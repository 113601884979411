import React from 'react'
import { useSelector } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'

import GlobalStyle from './styles/globalStyles'

import localMessages from './languages'
import * as media from './styles/mixins'

import Header from './components/Header/Header'

import HomeScreen from './screens/Home'
import GenresScreen from './screens/Genres'
import GenreScreen from './screens/Genre'
import GenreAllScreen from './screens/GenreAll'
import RegionsScreen from './screens/Regions'
import RegionScreen from './screens/Region'
import SongsScreen from './screens/Songs'
import AboutScreen from './screens/About'
import PartnersScreen from './screens/Partners'

// import ChernigivschynaScreen from './screens/regions/Chernigivschyna'

const StyledMainWrapper = styled.div`
  padding-top: 80px;
  max-width: 100vw;

  ${media.desktopM} {
    padding-top: 50px;
  }
`

function App() {
  const local = useSelector((state) => state.language.local)

  return (
    <IntlProvider
      locale={local}
      defaultLocale="ru"
      messages={localMessages[local]}
    >
      <GlobalStyle />
      <Header />
      <StyledMainWrapper>
        <Switch>
          <Route path="/genres/:genre" exact component={GenreScreen} />
          <Route path="/genres" exact component={GenresScreen} />
          <Route
            path="/regions/:region/genres/:genre"
            component={GenreScreen}
          />
          <Route path="/regions/:region/genres" component={GenreAllScreen} />
          <Route path="/regions/:region" component={RegionScreen} />
          <Route path="/regions" exact component={RegionsScreen} />
          <Route path="/songs" exact component={SongsScreen} />
          <Route path="/about" component={AboutScreen} />
          <Route path="/partners" component={PartnersScreen} />
          <Route path="/" exact component={HomeScreen} />
        </Switch>
      </StyledMainWrapper>
    </IntlProvider>
  )
}

export default App
