import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Media from 'react-media'

import MobileMenu from 'components/MobileMenu/MobileMenu'
import SearchMenu from 'components/SearchMenu/SearchMenu'
import GenresCircle from 'components/GenresCircle/GenresCircle'
import GenresRoom from 'components/GenresRoom/GenresRoom'
import { getGenresStructured } from 'redux/genresSlice'

export default function GenresScreen() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getGenresStructured())
  }, [dispatch])

  const isMobileMenuOpen = useSelector((state) => state.mobileMenu)
  const isSearchMenuOpen = useSelector((state) => state.searchMenu)

  return (
    <>
      {isMobileMenuOpen && <MobileMenu />}
      {isSearchMenuOpen && <SearchMenu />}
      <Media
        queries={{
          small: '(max-width: 768px)',
          large: '(min-width: 769px)',
        }}
      >
        {(matches) => (
          <>
            {matches.small && <GenresCircle />}
            {matches.large && <GenresRoom />}
          </>
        )}
      </Media>
    </>
  )
}
