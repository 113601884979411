import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { NavLink, Link } from 'react-router-dom'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'
import * as media from 'styles/mixins'

import { setEng, setUkr } from 'redux/languageSlice'

import Logo from '../Logo/Logo'
import LanguageSelect from '../LanguageSelect/LanguageSelect'
import MobileBurger from '../MobileBurger/MobileBurger'

const StyledHeaderWrapper = styled.div``

const StyledHeader = styled.header`
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 91%,
    98% 91%,
    94% 93%,
    91% 93%,
    88% 98%,
    86% 100%,
    82% 97%,
    79% 94%,
    76% 90%,
    74% 90%,
    70% 90%,
    67% 91%,
    65% 94%,
    61% 98%,
    59% 100%,
    57% 93%,
    54% 91%,
    51% 90%,
    49% 90%,
    46% 90%,
    44% 90%,
    41% 93%,
    39% 97%,
    35% 100%,
    32% 100%,
    30% 96%,
    27% 93%,
    24% 93%,
    22% 93%,
    19% 93%,
    16% 89%,
    13% 90%,
    11% 93%,
    8% 94%,
    4% 93%,
    2% 91%,
    0% 89%
  );
  background-image: url('/img/jpg/paper_texture_header.jpg');
  background-size: contain;
  position: fixed;
  top: 0px;
  z-index: 800;
  width: 100vw;
  max-width: 100vw;
`
const StyledNav = styled.nav`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 48px;
`
const StyledWrapper = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: -4px;

  ${media.desktopM} {
    height: 60px;
  }
`
const StyledCenterWrapper = styled.div`
  display: flex;
`

const StyledLink = styled(NavLink)`
  padding: 16px;
  font-family: 'Innerspace', serif;
  text-transform: uppercase;
  position: relative;
  font-size: 16px;
  color: #848484;
  text-decoration: none;
  word-spacing: 8px;
  z-index: 10;
  line-height: 2.2;
  margin-right: 10px;

  &:hover {
    & img {
      opacity: 0.2;
    }
  }

  &.active {
    color: #fff;

    & img {
      opacity: 1;
    }
  }

  ${media.desktopSM} {
    font-size: 10px;
    padding: 12px;
  }
`

const StyledHover = styled.img`
  position: absolute;
  top: 45%;
  left: 50%;
  width: 104%;
  height: 50px;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;

  ${media.desktopSM} {
    height: 34px;
  }
`

const StyledDropdownBackground = styled.div`
  width: 62px;
  height: 44px;
  position: fixed;
  z-index: 9999;
  top: 58px;
  right: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(
    30% 0%,
    76% 6%,
    100% 0,
    100% 100%,
    63% 98%,
    19% 94%,
    0 100%,
    0 5%
  );
  background-color: #fff;
`

const StyledDropdownWrapper = styled.div`
  width: 60px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/img/jpg/paper_texture_header.jpg');
  clip-path: polygon(
    30% 0%,
    76% 6%,
    100% 0,
    100% 100%,
    63% 98%,
    19% 94%,
    0 100%,
    0 5%
  );
  background-color: #fff;
`

const StyledLanguageButton = styled.button`
  font-family: 'Innerspace';
  word-spacing: 8px;
  text-transform: uppercase;
  display: block;
  padding: 10px;
  background: transparent;
  border: none;
  font-size: 14px;
  color: #848484;
  cursor: pointer;

  &:hover,
  &:focus {
    border: none;
    outline: none;
  }

  ${media.desktopSM} {
    font-size: 10px;
    padding: 12px;
  }
`

export default function Header() {
  const dispatch = useDispatch()

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  let dropdownLang

  if (useSelector((state) => state.language.local) === 'ukr') {
    dropdownLang = 'Eng'
  } else {
    dropdownLang = 'Укр'
  }

  const setLanguage = (language) => {
    setIsDropdownOpen(false)
    if (language === 'Укр') {
      dispatch(setUkr())
    } else {
      dispatch(setEng())
    }
  }

  return (
    <StyledHeaderWrapper>
      <StyledHeader>
        <div className="container">
          <StyledWrapper>
            <StyledNav>
              <Link to="/">
                <Logo />
              </Link>

              <Media
                query="(min-width: 769px)"
                render={() => (
                  <>
                    <StyledCenterWrapper>
                      <StyledLink to="/regions">
                        <FormattedMessage id="regions" />
                        <StyledHover src="/img/png/active_and_hover.png" />
                      </StyledLink>
                      <StyledLink to="/genres">
                        <FormattedMessage id="genres" />
                        <StyledHover src="/img/png/active_and_hover.png" />
                      </StyledLink>
                      <StyledLink to="/songs">
                        <FormattedMessage id="all_songs" />
                        <StyledHover src="/img/png/active_and_hover.png" />
                      </StyledLink>
                    </StyledCenterWrapper>
                    <StyledCenterWrapper>
                      <StyledLink to="/partners">
                        <FormattedMessage id="partners" />
                        <StyledHover src="/img/png/active_and_hover.png" />
                      </StyledLink>
                      <StyledLink to="/about">
                        <FormattedMessage id="about" />
                        <StyledHover src="/img/png/active_and_hover.png" />
                      </StyledLink>
                    </StyledCenterWrapper>
                  </>
                )}
              />
            </StyledNav>

            <Media
              queries={{
                small: '(max-width: 768px)',
                large: '(min-width: 769px)',
              }}
            >
              {(matches) => (
                <>
                  {matches.small && <MobileBurger />}
                  {matches.large && (
                    <LanguageSelect
                      isDropdownOpen={isDropdownOpen}
                      setDropdownOpen={setIsDropdownOpen}
                    />
                  )}
                </>
              )}
            </Media>
          </StyledWrapper>
        </div>
      </StyledHeader>
      <>
        {isDropdownOpen && (
          <StyledDropdownBackground>
            <StyledDropdownWrapper>
              <StyledLanguageButton onClick={() => setLanguage(dropdownLang)}>
                {dropdownLang}
              </StyledLanguageButton>
            </StyledDropdownWrapper>
          </StyledDropdownBackground>
        )}
      </>
    </StyledHeaderWrapper>
  )
}
