export const mobileS = '@media (max-width: 360px)'

export const mobileSM = '@media (max-width: 615px)'

export const mobile = '@media (max-width: 767px)'

export const tablet = '@media (min-width: 768px)'

export const tabletM = '@media (max-width: 768px)'

export const desktopS = '@media (min-width: 920px)'

export const desktopSM = '@media (min-width: 769px) and (max-width: 1100px) '

export const desktopM = '@media (max-width: 1070px)'

export const desktopL = '@media (min-width: 1700px)'

export const desktopXl = '@media (min-width: 2000px)'

export const lowScreen = '@media (max-height: 600px)'
