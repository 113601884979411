import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import songsAPI from 'api/songsAPI'

export const getSongs = createAsyncThunk(
  'songs/getAllSongs',
  async (params) => {
    const songs = await songsAPI.fetchSongs(params)
    return songs.data
  }
)

export const searchSongs = createAsyncThunk(
  'songs/searchSongs',
  async (params) => {
    const songs = await songsAPI.fetchSongsSearch(params)
    return songs.data
  }
)

const songsSlice = createSlice({
  name: 'songs',
  initialState: { songs: [], loading: true },
  reducers: {
    resetSongs(state, action) {
      state = { songs: null, loading: true }
    },
  },
  extraReducers: {
    [getSongs.pending]: (state, _) => ({ ...state, loading: true }),
    [searchSongs.pending]: (state, _) => ({ ...state, loading: true }),
    [getSongs.fulfilled]: (_, { payload }) => ({
      songs: payload,
      loading: false,
    }),
    [searchSongs.fulfilled]: (_, { payload }) => ({
      songs: payload,
      loading: false,
    }),
    [getSongs.pending]: (state, _) => ({ ...state, loading: true }),
    [searchSongs.pending]: (state, _) => ({ ...state, loading: true }),
  },
})

export const { resetSongs } = songsSlice.actions

export const songsReducer = songsSlice.reducer
