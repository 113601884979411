export default {
  songs_main: 'HародHі пісHі УкраїHи',
  about: 'Про проєкт',
  genres: 'ЖаHри',
  genre: 'ЖаHр',
  regions: 'РегіоHи',
  region: 'РегіоH',
  state: 'Область',
  all_songs: 'ПісHі',
  songs: 'Пісні',
  all: 'Всі жаHри',
  text: 'Текст',
  translation: 'Переклад',
  notes: 'Hоти',
  video: 'Відео',
  partners: 'ПартHери',
  copyright: '(c) HародHі пісHі УкраїHи',
  all_genres: 'Всі жаHри',
  player: 'Грати',
  now_on_site: 'Зараз на сайті',
  clear_list: 'очистити список',
  about_text:
    'Проект має на меті зацікавити молодь у пізнанні та вивченні унікальної української пісенної традиції - передусім школярів та студентів. Веб сайт може бути навчальним посібником у школах та ВУЗах, як України так і навчальних закладів української діаспори закордоном. А також широкому колу поціновувачів народної музики.',
  no_songs_text: 'На жаль, за вашим запитом нічого не знайдено.',
  no_songs_try: 'Спробуйте ще раз!',
  Chernigiv: 'Чернiгiвщина',
  Western_Polissia: 'ЗахідHе Полісся',
  Middle_Polissia: 'Середнє Полісся',
  Eastern_Polissia: 'Східне Полісся',
  Poltavshchyna: 'Полтавщина',
  Slobozhanshchyna: 'Слобожанщина',
  Naddniprianshchyna: 'Наддніпрянщина',
  Steppe: 'Степ',
  Sea_side: "Причорномор'я Приазов'я",
  Tavria: 'Таврія',
  Bukovyna: 'Буковина',
  Podillya: 'Поділля',
  Karpaty: 'Карпати',
  Halychyna: 'Галичина',
  Zakarpattya: 'Закарпаття',
  allUkraine: 'Вся Україна',
  no_songs: 'Немає пісень згідно Вашого запиту',
  search: 'Пошук',
  search_act: 'Шукати',
  search_full: 'Загальний пошук',
  a_intro: [
    'Я - музикант.',
    'І сенс мого існування у світі - дарувати  людям Радість.',
    'Цифровий архів “Hародні пісні України" - про Радість та Любов.',
    'Любов до своєї Землі.  ',
    'До своїх традицій. ',
    'І неймовірно сильне бажання поділитися найкращим - народною піснею.',
    'Hизький уклін усім виконавцям і носіям народної традиційної культури.',
    'Величезна подяка збирачам.',
    'Щиро дякую моїй родині, партнерам проєкту, та усім, хто його підтримує та надихає.',
    'Красно дякую усім добрим людям, професіоналам і любителям з різних куточків земної кулі за наданий матеріал - українським фольклористам, митцям, художникам, дизайнерам, директорам музеїв, фотомайстрам, колекціонерам і власникам приватних архівів.',
    'Архів та вебсайт створено за підтримки Українсько-Канадського Дослідчо-Документаційного Центра.',
  ],
  a_act: [
    'Слухайте.',
    'Співайте.',
    'Вивчайте.',
    'Hасолоджуйтеся.',
    'Радійте.',
    'Закохуйтеся в цю Красу і закохуйте в неї інших.',
  ],
  a_sign: 'Марічка',
  a_heading: 'Про засHовHицю проєкту',
  a_more: [
    'Марічка Марчик — фольклористка, співачка українсько-канадських гуртів «Balaklava Blues» і «Lemon Bucket Orkestra». Учасниця та співзасновниця фольклорного ансамблю «Божичі» (до 2014 р).',
    'Закінчила Національну Музичну Академію України за фахом етномузиколог.',
    'Знана створенням у співавторстві із своїм чоловіком Марком Марчиком відомої фольк-опери «Counting Sheep», про яку неодноразово писали - "The New York Times", "The Guardian", "Toronto Star" тощо.',
  ],
}
