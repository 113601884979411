import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { mobileSM, tabletM, desktopSM } from 'styles/mixins'

const StyledFooter = styled.footer`
  width: 100%;
  background-image: url('/img/jpg/paper_texture_header.jpg');
  background-size: contain;
  clip-path: polygon(
    0% 0%,
    0% 0%,
    0% 100%,
    100% 99%,
    100% 0%,
    92% 5%,
    83% 1%,
    75% 4%,
    67% 8%,
    58% 5%,
    50% 1%,
    43% 6%,
    31% 7%,
    24% 1%,
    12% 5%
  );
  position: fixed;
  bottom: 0px;
  z-index: 100;
`
const StyledFooterWrapper = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${desktopSM} {
    height: 80px;
  }

  ${tabletM} {
    height: 80px;
  }
`
const StyledSocialWrapper = styled.div`
  display: flex;
  margin-right: 36px;
`
const StyledSocialLink = styled.a`
  display: block;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 16px;
  }
`
const StyledSocialImage = styled.img`
  display: block;
  width: 38px;
  height: 38px;
`
const StyledFooterText = styled.p`
  font-family: 'Innerspace', serif;
  word-spacing: 8px;
  color: #848484;

  ${mobileSM} {
    font-size: 11px;
    line-height: 20px;
  }
`

export default function Footer() {
  return (
    <StyledFooter>
      <div className="container">
        <StyledFooterWrapper>
          <StyledSocialWrapper>
            <StyledSocialLink
              href="https://www.facebook.com/folksongsofukraine"
              title="Facebook"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <StyledSocialImage src="/img/png/facebook.png" />
            </StyledSocialLink>
            <StyledSocialLink
              href="https://www.instagram.com/folksongsofukraine/"
              title="Instagram"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <StyledSocialImage src="/img/png/instagram.png" />
            </StyledSocialLink>
            <StyledSocialLink
              href="https://www.youtube.com/channel/UChSNbOuibqwb_dxscxT0H0Q"
              title="Youtube"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <StyledSocialImage src="/img/png/youtube.png" />
            </StyledSocialLink>
            <StyledSocialLink
              href="https://soundcloud.com/user-270628857"
              title="Soundcloud"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <StyledSocialImage src="/img/png/soundcloud.png" />
            </StyledSocialLink>
          </StyledSocialWrapper>
          <StyledFooterText>
            <FormattedMessage id="copyright" />
          </StyledFooterText>
        </StyledFooterWrapper>
      </div>
    </StyledFooter>
  )
}
