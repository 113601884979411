import { createSlice } from '@reduxjs/toolkit'

import { getRegions } from './regionsSlice.js'
import { getGenres } from './genresSlice.js'
import { getSongs, searchSongs } from './songsSlice.js'

const loadingSlice = createSlice({
  name: 'loading',
  initialState: true,
  reducers: {},
  extraReducers: {
    [getRegions.pending]: (state, action) => true,
    [getGenres.pending]: (state, action) => true,
    [getSongs.pending]: (state, action) => true,
    [searchSongs.pending]: (state, action) => true,
    [getRegions.fulfilled]: (state, action) => false,
    [getGenres.fulfilled]: (state, action) => false,
    [getSongs.fulfilled]: (state, action) => false,
    [searchSongs.fulfilled]: (state, action) => false,
    [getRegions.rejected]: (state, action) => false,
    [getGenres.rejected]: (state, action) => false,
    [getSongs.rejected]: (state, action) => false,
    [searchSongs.rejected]: (state, action) => false,
  },
})

export const loadingReducer = loadingSlice.reducer
