import { createSlice, combineReducers } from '@reduxjs/toolkit'

const languageSlice = createSlice({
  name: 'language',
  initialState: 'ukr',
  reducers: {
    setEng: (_, action) => 'eng',
    setUkr: (_, action) => 'ukr',
  },
})

export const { setEng, setUkr } = languageSlice.actions

export default combineReducers({ local: languageSlice.reducer })
