import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import * as media from 'styles/mixins'
import styles from './window.module.css'

export default function Window(props) {
  const StyledWindowWrapper = styled.div((props) => ({
    position: 'absolute',
    bottom: props.offsetMobileY,
    left: props.offsetMobileX,
    zIndex: 10,
    width: props.fullWidth,
    height: props.fullHeight,
    transform: 'scale(0.75)',
    [media.mobileS]: {
      transform: 'scale(0.55)',
    },
    [media.tablet]: {
      left: '50%',
      bottom: '10vh',
      transform: `scale(0.75) translate(${props.offsetTabletX}px, ${props.offsetTabletY}px)`,
    },
    [media.desktopS]: {
      transform: `scale(0.7) translate(${props.offsetX}px, ${props.offsetY}px)`,
    },

    [media.desktopL]: {
      transform: `scale(0.7) translate(${props.offsetX * 1.05}px, ${
        +props.offsetY > -355 ? +props.offsetY * 1.2 : +props.offsetY * 1.22
      }px)`,
    },
    [media.desktopXl]: {
      transform: `scale(1) translate(${
        +props.offsetX > 200 ? +props.offsetX * 1.1 : +props.offsetX
      }px, ${
        +props.offsetY > -355 ? +props.offsetY * 1.09 : +props.offsetY * 1.03
      }px)`,
    },
  }))

  return (
    <Link to={`/regions/${props.id}`} className={styles.link}>
      <StyledWindowWrapper {...props}>
        <div
          className={styles.windowBack}
          style={{
            background: `url("./img/png/windows_sprite.png") ${props.spritePositionBackX} ${props.spritePositionBackY}`,
            width: props.fullWidth,
            height: props.fullHeight,
          }}
        />
        <div className={styles.windowHover}>
          <div
            className={styles.insideImg}
            style={{
              background: `url("./img/png/windows_inside_sprite.png") ${props.spritePositionInnerX} ${props.spritePositionInnerY}`,
              width: props.innerImgwidth,
              height: props.innerImgHeight,
            }}
          />
        </div>
        <div
          className={styles.windowLeft}
          style={{
            top: props.partsOffsetY,
            left: props.partsOffsetX,
            background: `url("./img/png/windows_sprite.png") ${props.spritePositionLeftDoorX} ${props.spritePositionLeftDoorY}`,
            width: props.width,
            height: props.height,
          }}
        />
        <div
          className={styles.windowRight}
          style={{
            top: props.partsOffsetY,
            right: props.rightPartOffsetX,
            background: `url("./img/png/windows_sprite.png") ${props.spritePositionRightDoorX} ${props.spritePositionRightDoorY}`,
            width: props.width,
            height: props.height,
          }}
        />
        <h6 className={styles[`${props.color}Title`]}>{props.regionName}</h6>
      </StyledWindowWrapper>
    </Link>
  )
}
