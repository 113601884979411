import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { toggleMobileMenu } from 'redux/mobileMenuSlice'
import LanguageSelect from '../LanguageSelect/LanguageSelect'
import * as media from 'styles/mixins'

const StyledWrapper = styled.div`
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 99;
  background-image: url('/img/jpg/genres-background.jpg');
  background-position: center center;
  background-size: contain;

  transition: all 0.5s ease;
`

const StyledContainer = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 64px;

  ${media.lowScreen} {
    padding: 12px;
  }
`

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 46px;

  ${media.lowScreen} {
    margin-bottom: 24px;
  }
`

const StyledLink = styled(NavLink)`
  padding: 10px;
  font-family: 'Innerspace', serif;
  word-spacing: 8px;
  text-transform: uppercase;
  position: relative;
  font-size: 16px;
  color: #848484;
  padding: 20px;
  text-decoration: none;
  line-height: 2.2;

  &:hover {
    & img {
      opacity: 0.2;
    }
  }

  &.active {
    color: #fff;

    & img {
      opacity: 1;
    }
  }
`

const StyledHover = styled.img`
  position: absolute;
  top: 45%;
  left: 50%;
  width: 104%;
  height: 50px;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;

  ${media.desktopSM} {
    height: 34px;
  }
`

export default function MobileMenu() {
  const dispatch = useDispatch()
  const isMobileMenuOpen = useSelector((state) => state.mobileMenu)

  function toggleMenu() {
    dispatch(toggleMobileMenu(!isMobileMenuOpen))
  }

  return (
    <StyledWrapper>
      <StyledContainer>
        <LanguageSelect />
        <StyledNav>
          <StyledLink to="/regions" onClick={toggleMenu}>
            <FormattedMessage id="regions" />
            <StyledHover src="/img/png/active_and_hover.png" />
          </StyledLink>
          <StyledLink to="/genres" onClick={toggleMenu}>
            <FormattedMessage id="genres" />
            <StyledHover src="/img/png/active_and_hover.png" />
          </StyledLink>
          <StyledLink to="/songs" onClick={toggleMenu}>
            <FormattedMessage id="all_songs" />
            <StyledHover src="/img/png/active_and_hover.png" />
          </StyledLink>
          <StyledLink to="/partners" onClick={toggleMenu}>
            <FormattedMessage id="partners" />
            <StyledHover src="/img/png/active_and_hover.png" />
          </StyledLink>
          <StyledLink to="/about" onClick={toggleMenu}>
            <FormattedMessage id="about" />
            <StyledHover src="/img/png/active_and_hover.png" />
          </StyledLink>
        </StyledNav>
      </StyledContainer>
    </StyledWrapper>
  )
}
