import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { mobileSM } from 'styles/mixins'
import { getLocalized } from 'utils/getLocalized'

const StyledBreadcrumbsWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0px;
  padding: 22px 70px;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  clip-path: polygon(
    0 0,
    100% 0,
    94% 96%,
    76% 96%,
    56% 98%,
    34% 96%,
    16% 96%,
    0 100%
  );

  ${mobileSM} {
    width: 100%;
    padding: 30px 30px 20px 30px;
    clip-path: none;
  }
`

const StyledBreadcrumbsText = styled.p`
  color: #fff;
  word-spacing: 8px;
  text-transform: none;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`

const StyledSeparator = styled.p`
  padding: 0px 12px;
  color: #fff;
`

export default function Breadcrumbs({ region, genre, allGenres }) {
  const isSearchMenuOpen = useSelector((state) => state.searchMenu)

  return (
    <>
      {!isSearchMenuOpen && (
        <StyledBreadcrumbsWrapper>
          {region && (
            <Link to={`/regions/${region.id}`}>
              <StyledBreadcrumbsText>
                {getLocalized(region, 'name')}
              </StyledBreadcrumbsText>
            </Link>
          )}
          {region && genre && <StyledSeparator> &gt; </StyledSeparator>}
          {allGenres && <StyledSeparator> &gt; </StyledSeparator>}
          {genre && (
            <Link to={`/genres/${genre.id}`}>
              <StyledBreadcrumbsText>
                {getLocalized(genre, 'name')}
              </StyledBreadcrumbsText>
            </Link>
          )}
          {allGenres && (
            <StyledBreadcrumbsText>
              <FormattedMessage id="all_genres" />
            </StyledBreadcrumbsText>
          )}
        </StyledBreadcrumbsWrapper>
      )}
    </>
  )
}
