import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Media from 'react-media'
import { FormattedMessage } from 'react-intl'

import GenresBarItemSimple from '../GenresBarItem/GenresBarItemSimple'
import GenresBarItemAllSimple from '../GenresBarItemAll/GenresBarItemAllSimple'
import { getLocalized } from 'utils/getLocalized'
import { mobileSM } from 'styles/mixins'

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 80;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 30px;
  background-image: url('/img/jpg/table_genres_BG.jpg');
  background-position: top center;
  background-size: 200%;

  ${mobileSM} {
    padding-top: 18px;
  }
`

const StyledList = styled.ul`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: no-wrap;
`

const StyledListMobile = styled.ul`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: calc(100vh - 180px);
  padding-top: 20px;
`

const StyledMenuHeading = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border: none;
  background: transparent;
  text-align: center;
  text-transform: none;
  color: #fff;
  margin: 0 auto 14px;
`

const StyledArrow = styled.img`
  margin-right: 14px;
  width: 32px;
  height: 32px;
`

export default function GenresBar({
  isGenresMenuOpen,
  setIsGenresMenuOpen,
  region,
}) {
  const genres = useSelector((state) => state.genres.genres)

  const closeGenresMenu = (event) => {
    if (event.target.tagName === 'DIV') {
      setIsGenresMenuOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', closeGenresMenu)

    return () => {
      window.removeEventListener('click', closeGenresMenu)
    }
  })

  return (
    <StyledWrapper>
      <div className="container">
        <Media
          queries={{
            small: '(max-width: 615px)',
            medium:
              '(min-width: 616px) and (max-width: 1010px) and (max-height: 615px)',
            large:
              '(min-width: 616px) and (max-width: 1010px) and (min-height: 616px)',
            extraLarge: '(min-width: 1011px)',
          }}
        >
          {(matches) => (
            <>
              {matches.small && (
                <>
                  <>
                    {!isGenresMenuOpen && (
                      <StyledMenuHeading
                        onClick={() => {
                          setIsGenresMenuOpen(true)
                        }}
                      >
                        <StyledArrow src="/img/svg/control_up.svg" />
                        <FormattedMessage id="genres" />
                      </StyledMenuHeading>
                    )}
                  </>
                  <>
                    {isGenresMenuOpen && (
                      <>
                        {genres?.length > 0 && (
                          <>
                            <StyledMenuHeading
                              onClick={() => {
                                setIsGenresMenuOpen(false)
                              }}
                            >
                              <StyledArrow src="/img/svg/control_down.svg" />
                              <FormattedMessage id="genres" />
                            </StyledMenuHeading>
                            <StyledListMobile>
                              {genres.map((genre) => (
                                <GenresBarItemSimple
                                  key={genre.id}
                                  name={getLocalized(genre, 'name')}
                                  image={genre.icon}
                                  link={genre.id}
                                  region={region}
                                />
                              ))}
                              <GenresBarItemAllSimple region={region} />
                            </StyledListMobile>
                          </>
                        )}
                      </>
                    )}
                  </>
                </>
              )}
              {matches.medium && (
                <>
                  <>
                    {!isGenresMenuOpen && (
                      <StyledMenuHeading
                        onClick={() => {
                          setIsGenresMenuOpen(true)
                        }}
                      >
                        <FormattedMessage id="genres" />
                      </StyledMenuHeading>
                    )}
                  </>
                  <>
                    {isGenresMenuOpen && (
                      <>
                        {genres?.length > 0 && (
                          <StyledList>
                            {genres.map((genre) => (
                              <GenresBarItemSimple
                                key={genre.id}
                                name={getLocalized(genre, 'name')}
                                image={genre.icon}
                                link={genre.id}
                                region={region}
                              />
                            ))}
                            <GenresBarItemAllSimple region={region} />
                          </StyledList>
                        )}
                      </>
                    )}
                  </>
                </>
              )}
              {matches.large && (
                <>
                  {genres?.length > 0 && (
                    <>
                      <StyledList>
                        {genres.slice(0, 6).map((genre) => (
                          <GenresBarItemSimple
                            key={genre.id}
                            name={getLocalized(genre, 'name')}
                            image={genre.icon}
                            link={genre.id}
                            region={region}
                          />
                        ))}
                      </StyledList>
                      <StyledList>
                        {genres.slice(6).map((genre) => (
                          <GenresBarItemSimple
                            key={genre.id}
                            name={getLocalized(genre, 'name')}
                            image={genre.icon}
                            link={genre.id}
                            region={region}
                          />
                        ))}
                        {region && <GenresBarItemAllSimple region={region} />}
                      </StyledList>
                    </>
                  )}
                </>
              )}
              {matches.extraLarge && (
                <>
                  {genres?.length > 0 && (
                    <StyledList>
                      {genres.map((genre) => (
                        <GenresBarItemSimple
                          key={genre.id}
                          name={getLocalized(genre, 'name')}
                          image={genre.icon}
                          link={genre.id}
                          region={region}
                        />
                      ))}
                      <GenresBarItemAllSimple region={region} />
                    </StyledList>
                  )}
                </>
              )}
            </>
          )}
        </Media>
      </div>
    </StyledWrapper>
  )
}
