import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Media from 'react-media'

import { desktopSM } from 'styles/mixins'
import { setEng, setUkr } from 'redux/languageSlice'
import { toggleMobileMenu } from 'redux/mobileMenuSlice'

const StyledWrapper = styled.div`
  position: relative;
`

const StyledButtonWrapper = styled.div`
  position: relative;
  z-index: 80;

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`
const StyledLanguageButton = styled.button`
  font-family: 'Innerspace';
  text-transform: uppercase;
  word-spacing: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: transparent;
  border: none;
  font-size: 14px;
  color: #848484;
  cursor: pointer;

  &:hover,
  &:focus {
    border: none;
    outline: none;
  }

  ${desktopSM} {
    font-size: 10px;
  }
`
const StyledMobileLanguageButton = styled.button`
  position: relative;
  font-family: 'Innerspace';
  word-spacing: 8px;
  text-transform: uppercase;
  display: block;
  padding: 10px;
  background: transparent;
  border: none;
  font-size: 14px;
  color: #848484;
  cursor: pointer;
  line-height: 2.2;

  &:hover,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    & img {
      opacity: 0.2;
    }
  }

  &.active {
    color: #fff;

    & img {
      opacity: 1;
    }
  }
`

const StyledHover = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 104%;
  height: 50px;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;

  ${desktopSM} {
    height: 34px;
  }
`

const StyledDropdownImage = styled.img`
  display: block;
  width: 20px;
  height: 20px;
  margin-bottom: 6px;
`

const StyledLanguageText = styled.p`
  margin-right: 10px;
`

export default function LanguageSelect({ setDropdownOpen }) {
  const dispatch = useDispatch()
  const language = useSelector((state) => state.language.local)

  let mainLang

  if (language === 'ukr') {
    mainLang = 'Укр'
  } else {
    mainLang = 'Eng'
  }

  const changeLanguage = () => {
    dispatch(toggleMobileMenu(false))
    if (language === 'ukr') {
      dispatch(setEng())
    } else {
      dispatch(setUkr())
    }
  }

  return (
    <Media
      queries={{
        small: '(max-width: 768px)',
        large: '(min-width: 769px)',
      }}
    >
      {(matches) => (
        <StyledWrapper>
          {matches.small && (
            <StyledButtonWrapper>
              <StyledMobileLanguageButton
                className={language === 'ukr' ? 'active' : ''}
                onClick={changeLanguage}
              >
                Укр
                <StyledHover src="/img/png/active_and_hover.png" />
              </StyledMobileLanguageButton>
              <StyledMobileLanguageButton
                className={language === 'eng' ? 'active' : ''}
                onClick={changeLanguage}
              >
                Eng
                <StyledHover src="/img/png/active_and_hover.png" />
              </StyledMobileLanguageButton>
            </StyledButtonWrapper>
          )}
          {matches.large && (
            <>
              <StyledButtonWrapper>
                <StyledLanguageButton
                  onClick={() => setDropdownOpen((prev) => !prev)}
                >
                  <StyledLanguageText>{mainLang}</StyledLanguageText>
                  <StyledDropdownImage
                    src="/img/svg/icon_drop_down.svg"
                    alt="dropdown"
                  />
                </StyledLanguageButton>
              </StyledButtonWrapper>
            </>
          )}
        </StyledWrapper>
      )}
    </Media>
  )
}
