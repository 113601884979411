import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import 'react-h5-audio-player/lib/styles.css'
import Media from 'react-media'

import { StyledAudioPlayer, StyledPlayerWrapper } from './styled'
import { setOpenSections } from 'redux/accordionSlice'

import './player.css'

export default function Player({
  song,
  nextSongTitle,
  prevSongTitle,
  children,
}) {
  const dispatch = useDispatch()

  const player = useRef(null)

  const handleClickNext = () => {
    if (nextSongTitle) {
      dispatch(setOpenSections({ [nextSongTitle]: true }))
    }
  }

  const handleClickPrevious = () => {
    if (prevSongTitle) {
      dispatch(setOpenSections({ [prevSongTitle]: true }))
    }
  }

  const getSongUrl = () => {
    const url = song?.audio?.url
    if (url) {
      return `${process.env.REACT_APP_CMS}${url}`
    }
  }

  const stopFirstSong = () => {
    if (!prevSongTitle) {
      player.current.audio.current.pause()
    }
  }

  return (
    <StyledPlayerWrapper>
      {children}
      <Media
        queries={{
          small: '(max-width: 615px)',
          large: '(min-width: 616px)',
        }}
      >
        {(matches) => (
          <>
            {matches.small && (
              <StyledAudioPlayer
                showSkipControls
                showJumpControls={false}
                autoPlay={false}
                autoPlayAfterSrcChange={true}
                onCanPlay={stopFirstSong}
                customIcons={{
                  play: <img src="/img/svg/control_play.svg" alt="play" />,
                  pause: <img src="/img/svg/control_pause.svg" alt="pause" />,
                  previous: (
                    <img src="/img/svg/control_back.svg" alt="previous" />
                  ),
                  next: <img src="/img/svg/control_forward.svg" alt="next" />,
                  volume: (
                    <img src="/img/svg/control_volume.svg" alt="volume" />
                  ),
                  volumeMute: (
                    <img src="/img/svg/no_sound_icon.svg" alt="mute" />
                  ),
                }}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                src={getSongUrl()}
                ref={player}
                onClickNext={handleClickNext}
                onClickPrevious={handleClickPrevious}
                onEnded={handleClickNext}
              />
            )}
            {matches.large && (
              <StyledAudioPlayer
                showSkipControls
                showJumpControls={false}
                showFilledVolume={false}
                autoPlay={false}
                autoPlayAfterSrcChange={true}
                onCanPlay={stopFirstSong}
                customIcons={{
                  play: <img src="/img/svg/control_play.svg" alt="play" />,
                  pause: <img src="/img/svg/control_pause.svg" alt="pause" />,
                  previous: (
                    <img src="/img/svg/control_back.svg" alt="previous" />
                  ),
                  next: <img src="/img/svg/control_forward.svg" alt="next" />,
                  volume: (
                    <img src="/img/svg/control_volume.svg" alt="volume" />
                  ),
                  volumeMute: (
                    <img src="/img/svg/no_sound_icon.svg" alt="mute" />
                  ),
                }}
                customAdditionalControls={[]}
                src={getSongUrl()}
                ref={player}
                onClickNext={handleClickNext}
                onClickPrevious={handleClickPrevious}
                onEnded={handleClickNext}
              />
            )}
          </>
        )}
      </Media>
    </StyledPlayerWrapper>
  )
}
