import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import MobileMenu from 'components/MobileMenu/MobileMenu'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import ModalWithButton from 'components/Modal/ModalWithButton'
import { getRegionById, resetCurrentRegion } from 'redux/regionsSlice'
import { desktopM } from 'styles/mixins'
import Iframe from 'components/Iframe/Iframe'
import SearchMenu from 'components/SearchMenu/SearchMenu'
import SearchBar from 'components/SearchBar/SearchBar'

const StyledBackground = styled.div`
  background-position: center bottom;
  background-size: cover;
  position: relative;
  z-index: 3;
  width: 100%;
  height: calc(100vh - 80px);

  ${desktopM} {
    height: calc(100vh - 50px);
  }
`

export default function GenreScreen() {
  const dispatch = useDispatch()
  const isMobileMenuOpen = useSelector((state) => state.mobileMenu)
  const isSearchMenuOpen = useSelector((state) => state.searchMenu)

  const region = useParams().region

  useEffect(() => {
    if (region) {
      dispatch(getRegionById(region))
    } else {
      dispatch(resetCurrentRegion())
    }
  }, [dispatch, region])

  const currentRegion = useSelector((state) => state.regions.currentRegion)

  return (
    <>
      {isMobileMenuOpen && <MobileMenu />}
      {isSearchMenuOpen && <SearchMenu />}
      {currentRegion && (
        <StyledBackground
          style={{
            backgroundImage: `url(/img/jpg/${region}_BG.jpg)`,
          }}
        >
          <Breadcrumbs region={currentRegion} allGenres={true} />
          <SearchBar />
          <Iframe src={`/animations/${region}/${region}.html`} />
          <ModalWithButton ethnic_regions={region} />
        </StyledBackground>
      )}
    </>
  )
}
