import axios from '../utils/Https'

const fetchRegions = () => axios.get('/ethnic-regions')

const fetchRegionById = (id) => axios.get(`/ethnic-regions/${id}`)

const fetchGenres = () => axios.get('/genres')

const fetchGenreById = (id) => axios.get(`/genres/${id}`)

const fetchSongs = ({ params }) => axios.get('/songs?_limit=-1', { params })

const fetchSongsCount = () => axios.get('/songs/count')

const fetchSongText = (id) => axios.get(`/songs/${id}/text`)

const fetchSongsSearch = (params) => axios.post('/songs/filter', params)

const fetchPartners = () => axios.get('/partners')

const fetchStates = () => axios.get('/regions')

export default {
  fetchRegions,
  fetchRegionById,
  fetchGenres,
  fetchGenreById,
  fetchSongs,
  fetchSongText,
  fetchSongsCount,
  fetchSongsSearch,
  fetchPartners,
  fetchStates,
}
