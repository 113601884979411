import React, { useEffect, useState, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { getSongs, resetSongs } from 'redux/songsSlice'
import { setIsPlayerMinimized, setOpenSections } from 'redux/accordionSlice'
import { getLocalized } from 'utils/getLocalized'
import Accordion from '../Accordion'
import Player from '../Player/Player'
import SongInfo from '../SongInfo/SongInfo'

import {
  StyledBackdrop,
  StyledModalWrapper,
  StyledModal,
  StyledList,
  StyledWoodenEdgeTop,
  StyledWoodenEdgeBottom,
  StyledButtonsWrapper,
  StyledButton,
  StyledImage,
} from './styled'

const StyledOpenPlayerImage = styled.img`
  display: block;
  width: 37px;
  height: 37px;
`

const StyledOpenPlayerWrapper = styled.div`
  position: fixed;
  bottom: 34px;
  left: 50%;
  z-index: 99999;
  transform: translateX(-50%);
  width: 154px;
  height: 50px;
  padding: 0px 6px;
  background-image: url('/img/png/open_player_BG.png');
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const StyledOpenPlayerText = styled.p`
  color: #fff;
  font-size: 18px;
  text-transform: none;
  margin-top: 2px;
  margin-right: 15px;
`

export default function ModalWithButton({ genres, ethnic_regions }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const songsListRef = createRef()

  const [currentSong, setCurrentSong] = useState('')
  const [prevSongTitle, setPrevSongTitle] = useState('')
  const [nextSongTitle, setNextSongTitle] = useState('')

  useEffect(() => {
    const params = {
      genres,
      ethnic_regions,
    }

    dispatch(getSongs({ params }))

    return () => {
      dispatch(resetSongs())
      dispatch(setIsPlayerMinimized(true))
    }
    // eslint-disable-next-line
  }, [dispatch, location.search])

  const changeState = () => {
    dispatch(setIsPlayerMinimized(!isPlayerMinimized))
  }

  const { isPlayerMinimized, openSections } = useSelector(
    (state) => state.accordion
  )
  const songs = useSelector((state) => state.songs.songs)
  const local = useSelector((state) => state.language.local)

  useEffect(() => {
    if (songs.length && local) {
      dispatch(setOpenSections({ [getLocalized(songs[0], 'name')]: true }))
    }
  }, [songs, dispatch, local])

  useEffect(() => {
    if (songs.length === 0) {
      return
    }
    if (Object.keys(openSections).length > 0) {
      songs.forEach((song, index, array) => {
        if (getLocalized(song, 'name') === Object.keys(openSections)[0]) {
          setPrevSongTitle(
            array[index - 1] ? getLocalized(array[index - 1], 'name') : ''
          )
          setNextSongTitle(
            array[index + 1] ? getLocalized(array[index + 1], 'name') : ''
          )
          setCurrentSong(song)
        }
      })
      return
    }

    setCurrentSong(songs[0])
    setPrevSongTitle('')
    setNextSongTitle(getLocalized(songs[1], 'name'))
  }, [songs, openSections, local])

  const scrollToTop = (index) => {
    songsListRef.current.scroll({ top: index * 80, behavior: 'smooth' })
  }

  return (
    <>
      {songs.length > 0 && (
        <StyledBackdrop>
          {isPlayerMinimized && (
            <StyledOpenPlayerWrapper onClick={changeState}>
              <StyledOpenPlayerImage src="/img/svg/control_down.svg" />
              <StyledOpenPlayerText>
                <FormattedMessage id="player" />
              </StyledOpenPlayerText>
            </StyledOpenPlayerWrapper>
          )}
          {!isPlayerMinimized && (
            <StyledModalWrapper>
              <StyledWoodenEdgeTop />
              {currentSong?.id && (
                <Player
                  song={currentSong}
                  prevSongTitle={prevSongTitle}
                  nextSongTitle={nextSongTitle}
                >
                  <StyledButtonsWrapper>
                    <StyledButton onClick={changeState}>
                      <StyledImage src="/img/svg/control_up.svg" />
                    </StyledButton>
                  </StyledButtonsWrapper>
                </Player>
              )}
              <StyledModal ref={songsListRef}>
                <StyledList
                  style={{
                    background: isPlayerMinimized ? 'transparent' : '#fff',
                  }}
                >
                  <Accordion>
                    {songs &&
                      songs.map((song, index, array) => (
                        <div
                          key={song.id}
                          song={song}
                          title={getLocalized(song, 'name')}
                          index={index}
                          setCurrentSong={setCurrentSong}
                          scrollToTop={scrollToTop}
                        >
                          <SongInfo song={currentSong} />
                        </div>
                      ))}
                  </Accordion>
                </StyledList>
              </StyledModal>
              <StyledWoodenEdgeBottom />
            </StyledModalWrapper>
          )}
        </StyledBackdrop>
      )}
    </>
  )
}
