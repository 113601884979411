import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  
  body {
    width: 100%;
    overflow-x: hidden;
    height: 100vh;
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Lato', serif;
  }

  #root{
    width: 100%;
    height: 100vh;
    min-height: 100%;
    margin: 0;
    padding: 0;
  }

  .container{
      width: 90vw;
      margin: 0 auto;
  }

  .active-language-button {
    
  }

  p, h1, h2, h3, h4, h5, h6, button, a, span {
    font-family: 'Innerspace', serif;
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    color: inherit;
    &:hover, :focus, :active {
      text-decoration: none;
      color: inherit;
    }
  }
`

export default GlobalStyle
