import styled from 'styled-components'
import AudioPlayer from 'react-h5-audio-player'

export const StyledPlayerWrapper = styled.div`
  width: 94%;
  position: relative;
  margin: 0 auto;
  padding-right: 3px;

  &::before {
    position: absolute;
    z-index: 60;
    content: '';
    display: block;
    width: 98%;
    padding-right: 4px;
    height: 20px;
    top: 0px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &::after {
    content: '';
    display: block;
    width: calc(100% - 4px);
    height: 20px;
    position: absolute;
    bottom: -19px;
    left: 0px;
    z-index: 10;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`

export const StyledAudioPlayer = styled(AudioPlayer)`
  background-image: url('/img/jpg/player_BG.jpg');
  background-size: cover;
  padding: 32px 32px 20px;
  position: relative;

  @media (max-width: 600px) {
    padding: 32px 14px 10px;
  }
`
