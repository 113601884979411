import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useFormik, FormikProvider, FieldArray } from 'formik'

import { toggleSearchMenu } from 'redux/searchMenuSlice'
import languages from '../../languages'
import { handleCheck, clearSelect } from 'utils/searchArraysUtil'
import { getGenres } from 'redux/genresSlice'
import { getRegions } from 'redux/regionsSlice'
import { getStates } from 'redux/statesSlice'

import {
  StyledWrapper,
  StyledContainer,
  StyledForm,
  StyledSearchInput,
  StyledSelectBlock,
  StyledSelectButton,
  StyledSelectLabel,
  StyledArrow,
  StyledCheckboxBlock,
  StyledCheckboxList,
  StyledCheckboxWrapper,
  StyledCheckboxLabel,
  StyledCheckbox,
  StyledDarkCheckboxLabel,
  StyledDarkCheckbox,
  StyledCheckboxContainer,
  StyledTransparentButton,
  StyledButton,
  StyledSearchGlass,
  StyledOKButton,
} from './styled'

export default function SearchMenu({
  selectedGenres,
  selectedEthnic_regions,
  selectedRegions,
  search,
  notes,
  video,
}) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [openedList, setOpenedList] = useState('')

  const convertedRegions = useRef(null)
  const convertedStates = useRef(null)
  const convertedGenres = useRef(null)

  function closeMenu() {
    dispatch(toggleSearchMenu(false))
  }

  useEffect(() => {
    dispatch(getGenres())
    dispatch(getRegions())
    dispatch(getStates())
  }, [dispatch])

  const language = useSelector((state) => state.language.local)
  const genres = useSelector((state) => state.genres.genres)
  const regions = useSelector((state) => state.regions.regions)
  const states = useSelector((state) => state.states.states)

  useEffect(() => {
    convertedGenres.current = genres?.map((genre) => ({
      ...genre,
      checked: selectedGenres?.find((g) => g === genre.id) ? true : false,
    }))
    convertedRegions.current = regions
      ? Object.values(regions).map((region) => ({
          ...region,
          checked: selectedEthnic_regions?.find((r) => r === region.id)
            ? true
            : false,
        }))
      : []
    convertedStates.current = states?.map((state) => ({
      ...state,
      checked: selectedRegions?.find((s) => s === state.id) ? true : false,
    }))
  }, [
    genres,
    regions,
    states,
    selectedGenres,
    selectedEthnic_regions,
    selectedRegions,
  ])

  const formik = useFormik({
    initialValues: {
      search: search ? search : '',
      regions: convertedRegions.current,
      states: convertedStates.current,
      genres: convertedGenres.current,
      notes: !!notes,
      video: !!video,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      closeMenu()
      submitSearch(values)
    },
  })

  function submitSearch(values) {
    const searchParams = new URLSearchParams()
    if (values.search) {
      searchParams.set('search', values.search)
    }
    if (values.notes) {
      searchParams.set('notes', values.notes)
    }
    if (values.video) {
      searchParams.set('video', values.video)
    }

    const regionsIDs = values.regions
      .filter((region) => region.checked)
      .map((region) => region.id)
    const statessIDs = values.states
      .filter((state) => state.checked)
      .map((state) => state.id)
    const genresIDs = values.genres
      .filter((genre) => genre.checked)
      .map((genre) => genre.id)

    regionsIDs.forEach((id) => searchParams.append('region', id))
    statessIDs.forEach((id) => searchParams.append('state', id))
    genresIDs.forEach((id) => searchParams.append('genre', id))

    history.push({
      pathname: '/songs',
      search: `?${searchParams}`,
    })
  }

  return (
    <StyledWrapper>
      <StyledContainer>
        <FormikProvider value={formik}>
          <StyledForm onSubmit={formik.handleSubmit}>
            <StyledSearchInput
              type="text"
              name="search"
              id="search"
              autocomplete="off"
              value={formik.values.search}
              placeholder={languages[language].search}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <StyledSelectBlock>
              <StyledSelectButton
                type="button"
                onClick={() => setOpenedList('regions')}
              >
                <StyledSelectLabel>
                  <FormattedMessage id="region" />
                </StyledSelectLabel>
                <StyledArrow src="/img/svg/control_down.svg" />
              </StyledSelectButton>
              <StyledSelectButton
                type="button"
                onClick={() => setOpenedList('states')}
              >
                <StyledSelectLabel>
                  <FormattedMessage id="state" />
                </StyledSelectLabel>
                <StyledArrow src="/img/svg/control_down.svg" />
              </StyledSelectButton>
              <StyledSelectButton
                type="button"
                onClick={() => setOpenedList('genres')}
              >
                <StyledSelectLabel>
                  <FormattedMessage id="genre" />
                </StyledSelectLabel>
                <StyledArrow src="/img/svg/control_down.svg" />
              </StyledSelectButton>
            </StyledSelectBlock>
            {openedList && (
              <StyledCheckboxBlock>
                {openedList === 'regions' && formik.values.regions && (
                  <>
                    <FieldArray
                      name="regions"
                      id="regions"
                      render={() => (
                        <StyledCheckboxList>
                          {formik.values.regions.map((region) => (
                            <StyledCheckboxContainer key={region.id}>
                              <StyledDarkCheckbox
                                type="checkbox"
                                name={region[`name_${language}`]}
                                id={region[`name_${language}`]}
                                checked={region.checked}
                                onChange={() =>
                                  handleCheck(
                                    formik.values.regions,
                                    region.id,
                                    formik.setFieldValue,
                                    'regions'
                                  )
                                }
                              />
                              <StyledDarkCheckboxLabel
                                htmlFor={region[`name_${language}`]}
                              >
                                {region[`name_${language}`]}
                              </StyledDarkCheckboxLabel>
                            </StyledCheckboxContainer>
                          ))}
                        </StyledCheckboxList>
                      )}
                    />
                    <div>
                      <StyledTransparentButton
                        onClick={() =>
                          clearSelect(
                            formik.values.regions,
                            formik.setFieldValue,
                            'regions'
                          )
                        }
                      >
                        <FormattedMessage id="clear_list" />
                      </StyledTransparentButton>
                      <StyledOKButton
                        type="button"
                        onClick={() => setOpenedList('')}
                      >
                        OK
                      </StyledOKButton>
                    </div>
                  </>
                )}
                {openedList === 'states' && formik.values.states && (
                  <>
                    <FieldArray
                      name="states"
                      id="states"
                      render={() => (
                        <StyledCheckboxList>
                          {formik.values.states.map((state) => (
                            <StyledCheckboxContainer key={state.id}>
                              <StyledDarkCheckbox
                                type="checkbox"
                                name={state[`name_${language}`]}
                                id={state[`name_${language}`]}
                                checked={state.checked}
                                onChange={() =>
                                  handleCheck(
                                    formik.values.states,
                                    state.id,
                                    formik.setFieldValue,
                                    'states'
                                  )
                                }
                              />
                              <StyledDarkCheckboxLabel
                                htmlFor={state[`name_${language}`]}
                              >
                                {state[`name_${language}`]}
                              </StyledDarkCheckboxLabel>
                            </StyledCheckboxContainer>
                          ))}
                        </StyledCheckboxList>
                      )}
                    />
                    <div>
                      <StyledTransparentButton
                        onClick={() =>
                          clearSelect(
                            formik.values.states,
                            formik.setFieldValue,
                            'states'
                          )
                        }
                      >
                        <FormattedMessage id="clear_list" />
                      </StyledTransparentButton>
                      <StyledOKButton
                        type="button"
                        onClick={() => setOpenedList('')}
                      >
                        OK
                      </StyledOKButton>
                    </div>
                  </>
                )}
                {openedList === 'genres' && formik.values.genres && (
                  <>
                    <FieldArray
                      name="genres"
                      id="genres"
                      render={() => (
                        <StyledCheckboxList>
                          {formik.values.genres.map((genre) => (
                            <StyledCheckboxContainer key={genre.id}>
                              <StyledDarkCheckbox
                                type="checkbox"
                                name={genre[`name_${language}`]}
                                id={genre[`name_${language}`]}
                                checked={genre.checked}
                                onChange={() =>
                                  handleCheck(
                                    formik.values.genres,
                                    genre.id,
                                    formik.setFieldValue,
                                    'genres'
                                  )
                                }
                              />
                              <StyledDarkCheckboxLabel
                                htmlFor={genre[`name_${language}`]}
                              >
                                {genre[`name_${language}`]}
                              </StyledDarkCheckboxLabel>
                            </StyledCheckboxContainer>
                          ))}
                        </StyledCheckboxList>
                      )}
                    />
                    <div>
                      <StyledTransparentButton
                        onClick={() =>
                          clearSelect(
                            formik.values.genres,
                            formik.setFieldValue,
                            'genres'
                          )
                        }
                      >
                        <FormattedMessage id="clear_list" />
                      </StyledTransparentButton>
                      <StyledOKButton
                        type="button"
                        onClick={() => setOpenedList('')}
                      >
                        OK
                      </StyledOKButton>
                    </div>
                  </>
                )}
              </StyledCheckboxBlock>
            )}
            <StyledCheckboxWrapper>
              <StyledCheckbox
                type="checkbox"
                name="notes"
                id="notes"
                checked={formik.values.notes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <StyledCheckboxLabel htmlFor="notes">
                <FormattedMessage id="notes" />
              </StyledCheckboxLabel>
              <StyledCheckbox
                type="checkbox"
                name="video"
                id="video"
                checked={formik.values.video}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <StyledCheckboxLabel htmlFor="video">
                <FormattedMessage id="video" />
              </StyledCheckboxLabel>
            </StyledCheckboxWrapper>
            <StyledButton type="submit">
              <StyledSearchGlass src="/img/svg/search_glass.svg" />
              <FormattedMessage id="search_act" />
            </StyledButton>
          </StyledForm>
        </FormikProvider>
      </StyledContainer>
    </StyledWrapper>
  )
}
