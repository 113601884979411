import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import GenresBar from 'components/GenresBar/GenresBar'
import MobileMenu from 'components/MobileMenu/MobileMenu'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import { mobile, desktopM } from 'styles/mixins'
import { getGenres } from 'redux/genresSlice'
import { getRegionById } from 'redux/regionsSlice'
import Iframe from 'components/Iframe/Iframe'
import SearchMenu from 'components/SearchMenu/SearchMenu'
import SearchBar from 'components/SearchBar/SearchBar'

const StyledBackground = styled.div`
  background-position: center bottom;
  background-size: cover;
  position: relative;
  z-index: 3;
  width: 100%;
  height: calc(100vh - 184px);

  ${desktopM} {
    height: calc(100vh - 154px);
  }

  @media (max-width: 1009px) {
    height: calc(100vh - 245px);
  }

  @media (max-width: 615px) {
    height: calc(100vh - 80px);
  }

  @media (min-width: 616px) and (max-width: 1010px) and (max-height: 615px) {
    height: calc(100vh - 80px);
  }
`

export const StyledClickDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 7;
`

const StyledBackdrop = styled.div`
  position: fixed;
  z-index: 50;
  top: 20px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);

  ${mobile} {
    display: none;
  }
`

export default function RegionScreen(props) {
  const dispatch = useDispatch()
  const isMobileMenuOpen = useSelector((state) => state.mobileMenu)
  const isSearchMenuOpen = useSelector((state) => state.searchMenu)

  const [isGenresMenuOpen, setIsGenresMenuOpen] = useState(false)

  const region = useParams().region

  useEffect(() => {
    dispatch(getGenres())
    dispatch(getRegionById(region))
  }, [dispatch, region])

  const [openedList, setOpenedList] = useState('')

  const currentRegion = useSelector((state) => state.regions.currentRegion)

  return (
    <>
      {isMobileMenuOpen && <MobileMenu />}
      {isSearchMenuOpen && <SearchMenu />}
      {currentRegion && (
        <StyledBackground
          style={{
            backgroundImage: `url(/img/jpg/${region}_BG.jpg)`,
          }}
        >
          <Breadcrumbs region={currentRegion} />
          <StyledClickDiv onClick={() => setOpenedList('')} />
          <SearchBar openedList={openedList} setOpenedList={setOpenedList} />
          <Iframe src={`/animations/${region}/${region}.html`} />
          {isGenresMenuOpen && <StyledBackdrop />}
        </StyledBackground>
      )}
      <GenresBar
        region={region}
        isGenresMenuOpen={isGenresMenuOpen}
        setIsGenresMenuOpen={setIsGenresMenuOpen}
      />
    </>
  )
}
