import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { getLocalized } from 'utils/getLocalized'
import { mobileSM } from 'styles/mixins'

export const StyledHeading = styled.h3`
  color: #353535;
  font-family: Open Sans;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${mobileSM} {
    max-width: 265px;
    height: 22px;
    overflow: hidden;
  }
`

const StyledLocation = styled.h4`
  color: #353535;
  font-family: Open Sans;
  font-weight: normal;
  font-style: italic;
  text-transform: none;
  font-size: 12px;
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${mobileSM} {
    max-width: 265px;
    height: 22px;
    overflow: hidden;
  }
`

export const StyledHeadingWrapper = styled.div`
  height: 80px;
  padding: 6px 32px;
  border-top: 1px solid rgba(233, 198, 169, 0.5);
  border-bottom: 1px solid rgba(233, 198, 169, 0.5);
  cursor: pointer;
  background: url('/img/jpg/song_BG.jpg');
`

export const AccordionSection = ({
  children,
  isOpen,
  song,
  onClick,
  index,
  setCurrentSong,
  scrollToTop,
}) => {
  const { isPlayerMinimized, openSections } = useSelector(
    (state) => state.accordion
  )

  const sameOrigin = Object.keys(openSections)[0] === getLocalized(song, 'name')
  const language = useSelector((state) => state.language.local)

  const handleClick = () => {
    setCurrentSong(song)
    onClick(getLocalized(song, 'name'))
    scrollToTop(index)
  }

  return (
    <div>
      {!isPlayerMinimized && !sameOrigin && (
        <StyledHeadingWrapper onClick={handleClick}>
          <StyledLocation>
            {song.ethnic_regions.length > 0 &&
              getLocalized(song.ethnic_regions[0], 'name')}
            ,
            {song.genres && song.genres.length > 0
              ? language === 'ukr'
                ? `${getLocalized(song.genres[0], 'name_singular')}`
                : `${getLocalized(song.genres[0], 'name')}`
              : ''}
          </StyledLocation>
          <StyledHeading>{getLocalized(song, 'name')}</StyledHeading>
          <StyledLocation>{getLocalized(song, 'location')}</StyledLocation>
        </StyledHeadingWrapper>
      )}
      {isOpen && children}
    </div>
  )
}
