import styled from 'styled-components'
import * as media from 'styles/mixins'

export const StyledBackdrop = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 80px);
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  ${media.desktopM} {
    height: calc(100vh - 50px);
    max-height: calc(100vh - 50px);
  }
`

export const StyledModalWrapper = styled.div`
  position: relative;
  z-index: 10;
  width: 70%;
  max-height: 78%;
  margin-bottom: 10px;

  ${media.tabletM} {
    width: 80%;
  }

  ${media.mobileSM} {
    width: 96%;
  }
`

export const StyledModal = styled.div`
  position: relative;
  z-index: 10;
  width: 94%;
  margin: 0 auto;
  max-height: calc(100% - 160px);
  overflow-y: scroll;
  overflow-x: visible;
  background: transparent;
  scrollbar-color: #fff transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`
export const StyledList = styled.ul`
  width: 100%;
  position: relative;
`

export const StyledWoodenEdgeTop = styled.div`
  width: 96%;
  margin-left: 2%;
  height: 20px;
  background-image: url('/img/png/wooden_middle.png');
  background-size: cover;
  position: relative;
  border-radius: 4px;
`

export const StyledWoodenEdgeBottom = styled.div`
  width: 96%;
  margin-left: 2%;
  height: 20px;
  background-image: url('/img/png/wooden_middle.png');
  background-size: cover;
  position: relative;
  border-radius: 4px;
  bottom: 2px;
`

export const StyledButtonsWrapper = styled.div`
  position: absolute;
  top: 64px;
  left: 32px;
  z-index: 60;
  display: flex;

  ${media.mobileSM} {
    left: 12px;
    top: 62px;
  }
`

export const StyledButton = styled.button`
  display: block;
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: 14px;
  }
`

export const StyledImage = styled.img`
  display: block;

  ${media.mobileSM} {
    height: 40px;
  }
`

export const StyledEmptyWrapper = styled.div`
  height: 120px;
  padding: 6px 32px;
  border-top: 1px solid rgba(233, 198, 169, 0.5);
  border-bottom: 1px solid rgba(233, 198, 169, 0.5);
  cursor: initial;
  background: url('/img/jpg/song_BG.jpg');
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledText = styled.p`
  font-family: Open Sans;
  text-align: center;
  text-transform: none;
  margin-bottom: 14px;
`

export const StyledBoldText = styled.p`
  font-family: Open Sans;
  text-align: center;
  text-transform: none;
  font-weight: 600;
`
