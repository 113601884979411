import styled from 'styled-components'
import { tabletM } from 'styles/mixins'

export const StyledContainer = styled.div`
  position: absolute;
  z-index: 15;
  top: 0px;
  right: 0px;
  left: 0;
`

export const StyledSearchWrapper = styled.div`
  position: absolute;
  z-index: 15;
  top: 0px;
  right: 0px;
  padding: 16px 40px;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  clip-path: polygon(
    0 100%,
    5% 0,
    100% 0,
    100% 100%,
    72% 98%,
    42% 99%,
    22% 99%
  );

  ${tabletM} {
    display: none;
  }
`

export const StyledSearch = styled.button`
  display: block;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const StyledIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`

export const StyledSearchText = styled.p`
  color: #fff;
  word-spacing: 8px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`

export const StyledBarWrapper = styled.div`
  width: 100%;
  z-index: 15;
  padding: 12px 40px 6px;
  background-color: rgba(0, 0, 0, 0.5);

  ${tabletM} {
    display: none;
  }
`

export const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
`

export const StyledSearchInput = styled.input`
  width: 400px;
  height: 30px;
  padding: 0 20px;
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  color: #fff;
  outline: none !important;
  outline-offset: 0 !important;
  outline-color: transparent !important;

  &:focus-visible {
    outline: none !important;
    outline-color: transparent !important;
    outline-offset: 0 !important;
  }

  &::placeholder {
    font-family: 'Innerspace', serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
  }

  &:focus::placeholder {
    color: transparent;
  }
`

export const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledSelectButton = styled.button`
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
`

export const StyledSelectLabel = styled.p`
  font-family: 'Innerspace', serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin-right: 14px;
`

export const StyledCheckbox = styled.input`
  opacity: 0;
  width: 80px;
  height: 20px;
  position: relative;
  left: 50px;
  z-index: 400;
  cursor: pointer;

  & + label::before {
    background-image: url('/img/svg/radio_disabled.svg');
  }

  &:checked + label::before {
    background-image: url('/img/svg/radio_enabled.svg');
  }
`

export const StyledCheckboxLabel = styled.label`
  font-family: 'Innerspace', serif;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: -33px;
    transform: translateY(-50%);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

export const StyledCheckboxBlock = styled.div`
  max-height: 80vh;
  position: absolute;
  top: 36px;
  left: -20px;
  background-color: #fff;
  border-radius: 6px;
  padding: 36px;
  z-index: 400;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledCheckboxList = styled.ul`
  max-height: calc(100vh - 460px);
  overflow-y: scroll;
  padding: 10px 0;
  scrollbar-color: #353535 transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #353535;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #353535;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`

export const StyledCheckboxContainer = styled.li`
  padding-left: 34px;
  margin-bottom: 22px;
  margin-right: 10px;
  display: flex;
`

export const StyledTransparentButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  margin: 20px 0;
  cursor: pointer;
`

export const StyledTransparentWhiteButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
`

export const StyledDarkCheckbox = styled.input`
  display: none;

  & + label::before {
    background-image: url('/img/svg/radio_disabled_dark.svg');
  }

  &:checked + label::before {
    background-image: url('/img/svg/radio_enabled_dark.svg');
  }
`

export const StyledOKButton = styled.button`
  width: 88px;
  height: 45px;
  background: url('/img/svg/ok_button.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
`

export const StyledDarkCheckboxLabel = styled.label`
  font-family: 'Innerspace', serif;
  text-transform: capitalize;
  font-size: 14px;
  cursor: pointer;
  position: relative;

  &:not(:last-of-type) {
    margin-right: 14px;
  }

  &::before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: -33px;
    transform: translateY(-50%);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`

export const StyledDropdownWrapper = styled.div`
  position: relative;
`

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 126px;
  height: 40px;
  background: url('/img/svg/search_button_background.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
  outline: none;
  color: #1b100e;
  cursor: pointer;
`

export const StyledCloseButton = styled.button`
  width: 26px;
  height: 34px;
  background: url('/img/svg/close_bar_icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
  outline: none;
  cursor: pointer;
`

export const StyledInfoWrapper = styled.div`
  width: 100%;
  z-index: 15;
  padding: 24px 40px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${tabletM} {
    display: none;
  }
`

export const StyledInfoTextWrapper = styled.p``

export const StyledSubheadingText = styled.b`
  font-family: 'Innerspace', serif;
  color: #d6c094;
  text-transform: none;
  font-size: 12px;
  margin-right: 10px;
`

export const StyledInfoText = styled.i`
  font-family: 'Innerspace', serif;
  color: #fff;
  text-transform: none;
  font-size: 12px;
  margin-right: 30px;
  word-spacing: 8px;
`
