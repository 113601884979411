import React from 'react'
import styled from 'styled-components'

const StyledIframe = styled.iframe`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
`

export default function Iframe({ src, children, flex }) {
  return (
    <StyledIframe
      src={src}
      style={
        flex
          ? {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'translate(-50%)',
            }
          : {}
      }
    >
      {children}
    </StyledIframe>
  )
}
