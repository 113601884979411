import styled, { keyframes } from 'styled-components'
import { desktopM, tabletM } from 'styles/mixins'

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const wigglingSmall = keyframes`
0% { transform: rotate(10deg); }
50% { transform: rotate(-10deg); }
100% { transform: rotate(10deg); }
`

const swaying = keyframes`
0% { transform: translate(25px, 0); }
50% { transform: translate(-25px, 0); }
100% { transform: translate(25px, 0); }
`

const jumping = keyframes`
  0% { transform: scale(1); 
        top: 0px;}
  48% { transform: scale(1); 
    top: 0px;}
 50% { transform: scale(1, 0.9); 
  top: 0px;}
 51% { transform: scale(1); 
  top: -50px;}
  52% { transform: scale(1); 
    top: 0px;}
    53% { transform: scale(1, 0.9); 
      top: 0px;}
      54% { transform: scale(1); 
        top: 0px;}

100% { transform: scale(1); 
  top: 0px;}
`

const jumpingH = keyframes`
  0% {top: 0px;}
  48% {top: 0px;}
 50% { top: 0px;}
 51% { top: -50px;}
  52% {top: 0px;}
    53% {top: 0px;}
      54% { top: 0px;}
100% {top: 0px;}
`

const glowingStepOne = keyframes`
0% { opacity: 0; }
20% { opacity: 0; 
transform: scale(0.3)}
45% { opacity: 0.8; 
transform: scale(1.5)}
55% { opacity: 0; 
transform: scale(2);}
100% { opacity: 0; }
`

const glowingStepTwo = keyframes`
0% { opacity: 0; }
45% { opacity: 0; 
transform: scale(0.3)}
60% { opacity: 0.8; 
transform: scale(1.5);}
70% {
  opacity: 0;
  transform: scale(2);
}
100% { opacity: 0; }
`

const breathing = keyframes`
  0% { transform: scale(1); }
  40% { transform: scale(1); }
 50% { transform: scale(1.1, 0.9);}
 60% { transform: scale(0.9, 1.1);}
  70% { transform: scale(1); }
100% { transform: scale(1);`

const flying = keyframes`
0%{
  transform: scale(1);
}
42% {
  transform: scale(1, 0.9);
}
65% {
  transform: scale(1, 1.4);
}
67% {
  transform: scale(1, 1.4);
}
80% {
  transform: scale(1);
}
`

const shaking = keyframes`
0% { transform: rotate(0deg); }
40% { transform: rotate(-6deg); }
70% { transform: rotate(6deg); }
100% { transform: rotate(0deg); }
`

const blowing = keyframes`
0%{transform: rotate(-1deg)}
20%{transform: rotate(0deg)}
40%{transform: rotate(6deg)}
40%{transform: rotate(7deg)}
50%{transform: rotate(6deg)}
70%{transform: rotate(0deg)}
80%{transform: rotate(3deg)}
90%{transform: rotate(0deg)}
100%{transform: rotate(-1deg)}
`

export const StyledClickDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 7;
`

export const StyledWrapper = styled.div`
  background-image: url('./img/jpg/genres_BG.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: calc(100vh - 80px);
  min-height: 620px;
  position: relative;
  z-index: 3;
  overflow: hidden;

  ${desktopM} {
    height: calc(100vh - 50px);
  }

  ${tabletM} {
    min-width: 0;
    min-height: 0;
    height: auto;
  }
`
export const StyledIframe = styled.iframe`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 108%;
  display: flex;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
`

export const StyledName = styled.p`
  position: relative;
  z-index: 9;
  padding: 10px;
  color: #fff;
  text-transform: none;
  text-align: center;
  max-width: 220px;
  line-height: 1.4;
`

export const StyledGarbuzWrapper = styled.div`
  position: absolute;
  z-index: 9;
  bottom: 6%;
  left: 50%;
  transform: translate(1vw);
  cursor: pointer;
`

export const StyledGarbuz = styled.div`
  position: relative;
  background: url('./img/png/genres_sprite.png') -737px -507px;
  width: 134px;
  height: 100px;
  margin: 0 auto;
  transform-otigin: center bottom;
  animation: ${jumping} 10s ease-in-out infinite;
`

export const StyledHorseWrapper = styled.div`
  position: absolute;
  z-index: 9;
  bottom: 0%;
  left: 50%;
  transform: translate(-33vw);
  cursor: pointer;

  @media (min-width: 1340px) {
    transform: translate(-29vw);
  }

  @media (min-width: 1500px) {
    transform: translate(-26vw);
  }
`

export const StyledHorse = styled.div`
  position: relative;
  background: url('./img/png/genres_sprite.png') -702px -10px;
  width: 110px;
  height: 130px;
  margin: 0 auto;
  animation: ${jumpingH} 14s ease-in-out infinite;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -3px;
    right: 5px;
    background: url('./img/png/genres_sprite.png') -819px -6px;
    width: 30px;
    height: 50px;
    transform-origin: left center;
    animation: ${shaking} 8s ease-in-out infinite;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 30px;
    right: 5px;
    background: url('./img/png/genres_sprite.png') -819px -53px;
    width: 30px;
    height: 50px;
    transform-origin: left center;
    animation: ${shaking} 10s ease-in-out infinite;
  }
`

export const StyledKorovaiWrapper = styled.div`
  position: absolute;
  z-index: 9;
  bottom: 30%;
  left: 50%;
  transform: translate(-19vw);
  cursor: pointer;

  @media (min-width: 1340px) {
    transform: translate(-15vw);
  }

  @media (min-width: 1500px) {
    transform: translate(-13vw);
  }
`

export const StyledKorovai = styled.div`
  position: relative;
  z-index: 20;
  background: url('./img/png/genres_sprite.png') -471px -10px;
  width: 122px;
  height: 80px;
  margin: 0 auto;
`

export const StyledKorovaiRibbonOne = styled.div`
  position: absolute;
  top: 80px;
  left: 22px;
  z-index: 15;
  background: url('./img/png/genres_sprite.png') -480px -132px;
  width: 30px;
  height: 150px;
  margin: 0 auto;
  transform-origin: center top;
  animation: ${blowing} 6s ease-in-out infinite;
`

export const StyledKorovaiRibbonTwo = styled.div`
  position: absolute;
  top: 80px;
  left: 82px;
  z-index: 15;
  background: url('./img/png/genres_sprite.png') -523px -110px;
  width: 30px;
  height: 176px;
  margin: 0 auto;
  transform-origin: center top;
  animation: ${blowing} 7s ease-in-out infinite;
`

export const StyledKorovaiRibbonThree = styled.div`
  position: absolute;
  top: 80px;
  left: 52px;
  z-index: 15;
  background: url('./img/png/genres_sprite.png') -562px -132px;
  width: 30px;
  height: 150px;
  margin: 0 auto;
  transform-origin: center top;
  animation: ${blowing} 9s ease-in-out infinite;
`

export const StyledLastivkaWrapper = styled.div`
  position: absolute;
  z-index: 9;
  bottom: 34%;
  left: 50%;
  transform: translate(-44vw);
  cursor: pointer;

  @media (min-width: 1240px) {
    transform: translate(-38vw);
  }

  @media (min-width: 1500px) {
    transform: translate(-35vw);
  }

  @media (min-width: 1920px) {
    bottom: 37%;
    transform: translate(-40vw);
  }

  @media (min-width: 2000px) {
    transform: translate(-37vw);
  }
`

export const StyledLastivka = styled.div`
  position: relative;
  top: 10px;
  left: 20px;
  z-index: 20;
  background: url('./img/png/genres_sprite.png') -471px -763px;
  width: 120px;
  height: 82px;
  z-index: 20;
  margin: 0 auto;
`

export const StyledLastivkaTopWing = styled.div`
  position: absolute;
  top: 55px;
  left: 44px;
  background: url('./img/png/genres_sprite.png') -487px -708px;
  width: 80px;
  height: 54px;
  z-index: 20;
  margin: 0 auto;
  transform-origin: center bottom;
  animation: ${flying} 10s ease-in-out infinite;
`

export const StyledLastivkaBottomWing = styled.div`
  position: absolute;
  bottom: 88px;
  left: 49px;
  background: url('./img/png/genres_sprite.png') -480px -853px;
  width: 80px;
  height: 32px;
  z-index: 20;
  margin: 0 auto;
  transform-origin: center top;
  animation: ${flying} 10s ease-in-out infinite;
`

export const StyledNamystoWrapper = styled.div`
  position: absolute;
  z-index: 9;
  bottom: 21%;
  left: 50%;
  transform: translate(18vw);
  cursor: pointer;

  @media (min-width: 1240px) {
    transform: translate(17vw);
  }

  @media (min-width: 1340px) {
    transform: translate(13.5vw);
  }

  @media (min-width: 1700px) {
    bottom: 21%;
    transform: translate(13vw);
  }

  @media (min-width: 1920px) {
    bottom: 26%;
    transform: translate(18vw);
  }

  @media (min-width: 2000px) {
    transform: translate(15vw);
  }
`

export const StyledNamysto = styled.div`
  background: url('./img/png/genres_sprite.png') -969px -10px;
  width: 84px;
  height: 92px;
  margin: 0 auto;
  transform-origin: center top;
  animation: ${wigglingSmall} 10s ease-in-out infinite;
`

export const StyledNest = styled.div`
  background: url('./img/png/genres_sprite.png') -702px -672px;
  width: 154px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  z-index: 15;
  top: -57px;
  left: -10px;
`

export const StyledSnipWrapper = styled.div`
  position: absolute;
  z-index: 9;
  z-index: 30;
  bottom: 6%;
  left: 50%;
  transform: translate(30vw);
  cursor: pointer;

  @media (min-width: 1340px) {
    transform: translate(26vw);
  }

  @media (min-width: 1920px) {
    transform: translate(30vw);
  }

  @media (min-width: 2000px) {
    transform: translate(28vw);
  }
`

export const StyledSnip = styled.div`
  position: relative;
  z-index: 90;
  background: url('./img/png/genres_sprite.png') -471px -389px;
  width: 90px;
  height: 204px;
  margin: 0 auto;
  transform-origin: center bottom;
  animation: ${breathing} 10s ease-in-out infinite;
`

export const StyledVinokWrapper = styled.div`
  position: absolute;
  z-index: 9;
  top: 3%;
  left: 50%;
  transform: translate(25vw);
  cursor: pointer;

  @media (min-width: 1240px) {
    transform: translate(23vw);
  }

  @media (min-width: 1920px) {
    top: 8%;
  }
`

export const StyledVinok = styled.div`
  background: url('./img/png/genres_sprite.png') -702px -264px;
  margin: 0 auto;
  width: 132px;
  height: 124px;
  transform-origin: center top;
  animation: ${wigglingSmall} 30s linear infinite;
`

export const StyledZirkaWrapper = styled.div`
  position: absolute;
  z-index: 9;
  bottom: 43%;
  left: 50%;
  transform: translate(36vw);
  cursor: pointer;

  @media (min-width: 1240px) {
    transform: translate(34vw);
  }

  @media (min-width: 1240px) {
    transform: translate(31vw);
  }

  @media (min-width: 1700px) {
    bottom: 39%;
    transform: translate(29vw);
  }

  @media (min-width: 1920px) {
    bottom: 38%;
    transform: translate(34vw);
  }

  @media (min-width: 2000px) {
    transform: translate(31vw);
  }
`

export const StyledZirka = styled.div`
  position: relative;
  background: url('./img/png/genres_sprite.png') -34px -387px;
  width: 124px;
  height: 124px;
  margin: 0 auto;
  z-index: 30;
  animation: ${rotation} 20s reverse infinite;
`

export const StyledPatyk = styled.div`
  position: absolute;
  top: 72px;
  left: 23px;
  z-index: -1;
  transform: rotate(30deg);
  background: url('./img/png/genres_sprite.png') -305px -387px;
  width: 20px;
  height: 106px;
  margin: 0 auto;
`

export const StyledCrossWrapper = styled.div`
  position: absolute;
  z-index: 9;
  top: 12%;
  left: 51%;
  transform: translate(-4vw);
  cursor: pointer;

  @media (min-width: 1700px) {
    transform: translate(-3vw);
  }

  @media (min-width: 2000px) {
    transform: translate(-2.5vw);
  }
`

export const StyledCross = styled.div`
  background: url('./img/png/genres_sprite.png') -969px -162px;
  width: 56px;
  height: 76px;
  margin: 0 auto;

  &::before {
    content: '';
    display: block;
    position: relative;
    opacity: 0;
    z-index: -1;
    background: url('./img/png/genres_sprite.png') -890px -162px;
    width: 56px;
    height: 76px;
    animation: ${glowingStepOne} 10s linear infinite;
  }

  &::after {
    content: '';
    display: block;
    position: relative;
    top: -80px;
    opacity: 0;
    z-index: -2;
    background: url('./img/png/genres_sprite.png') -890px -162px;
    width: 56px;
    height: 76px;
    animation: ${glowingStepTwo} 10s linear infinite;
  }
`

export const StyledCatWrapper = styled.div`
  position: absolute;
  z-index: 9;
  top: 30%;
  left: 50%;
  transform: translate(-67px);
  cursor: pointer;
`

export const StyledCat = styled.div`
  background: url('./img/png/genres_sprite.png') -10px -25px;
  width: 258px;
  height: 190px;
  margin: 0 auto;
  animation: ${swaying} 10s ease-in-out infinite;
`

export const StyledCatName = styled.p`
  position: relative;
  top: 78px;
  left: 25%;
  padding: 10px;
  color: #fff;
  text-transform: none;
  text-align: center;
  max-width: 150px;
  line-height: 1.4;
`
