import { createSlice } from '@reduxjs/toolkit'

import { getRegions } from './regionsSlice.js'
import { getGenres } from './genresSlice.js'
import { getSongs, searchSongs } from './songsSlice.js'

const errorsSlice = createSlice({
  name: 'errors',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getRegions.rejected]: (state, { payload }) =>
      payload ? payload : 'Error occured',
    [getGenres.rejected]: (state, { payload }) =>
      payload ? payload : 'Error occured',
    [getSongs.rejected]: (state, { payload }) =>
      payload ? payload : 'Error occured',
    [searchSongs.rejected]: (state, { payload }) =>
      payload ? payload : 'Error occured',
  },
})

export const errorsReducer = errorsSlice.reducer
