import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import MobileMenu from 'components/MobileMenu/MobileMenu'
import * as media from 'styles/mixins'
import texts from 'languages'
import Iframe from 'components/Iframe/Iframe'

const StyledAboutImage = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 80px);
  background-image: url('./img/jpg/about_BG.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

  ${media.desktopM} {
    min-height: calc(100vh - 50px);
  }
`

const StyledBackdrop = styled.div`
  position: relative;
  z-index: 10;
  width: 50%;
  max-height: calc(100vh - 80px);
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 30px 60px;
  scrollbar-color: #fff transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ${media.desktopM} {
    min-height: calc(100vh - 50px);
  }

  ${media.tabletM} {
    width: 100%;
    padding: 30px;
  }
`
const StyledContainer = styled.div``

const StyledParagraph = styled.div`
  margin-bottom: 40px;
`

const StyledHeading = styled.h3`
  padding: 20px 0px;
  font-family: Innerspace, sans-serif;
  word-spacing: 8px;
  font-size: 1.5rem;
  line-height: 33px;
  text-transform: uppercase;
  color: #fff;
`

const StyledText = styled.h3`
  font-family: Innerspace, sans-serif;
  word-spacing: 8px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  text-transform: none;
  & span {
    text-transform: uppercase;
    font-weight: bold;
  }
`

const StyledCreatorText = styled.h3`
  font-family: Opan Sans, sans-serif;
  letter-spacing: 0.7px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  text-transform: none;
`

const StyledSpan = styled.span`
  display: block;
  font-family: Opan Sans, sans-serif;
  letter-spacing: 0.7px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  text-transform: none;
`

const StyledLink = styled.a`
  font-family: Opan Sans, sans-serif;
  letter-spacing: 0.7px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-transform: none;
  color: #00d2df;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #00d2df;
    text-decoration: none;
  }
`

const StyledInnerWrapper = styled.div`
  margin-bottom: 14px;
`
const StyledLogo = styled.img`
  padding: 20px 0px;
`

export default function AboutScreen() {
  const isMobileMenuOpen = useSelector((state) => state.mobileMenu)

  const local = useSelector((state) => state.language.local)

  return (
    <>
      {isMobileMenuOpen && <MobileMenu />}

      <StyledAboutImage>
        <div className="container">
          <Iframe
            src="/animations/about/about_project_animation.html"
            flex={true}
          />
          <StyledBackdrop>
            <StyledContainer>
              <StyledParagraph>
                {texts[local].a_intro.map((text) => (
                  <StyledInnerWrapper>
                    <StyledText>{text}</StyledText>
                  </StyledInnerWrapper>
                ))}
              </StyledParagraph>
              <StyledParagraph></StyledParagraph>
              <StyledParagraph>
                {texts[local].a_act.map((text) => (
                  <StyledText>{text}</StyledText>
                ))}
              </StyledParagraph>
              <StyledParagraph>
                <StyledText>
                  <FormattedMessage id="a_sign" />
                </StyledText>
              </StyledParagraph>
              <StyledParagraph>
                <StyledHeading>
                  <FormattedMessage id="a_heading" />
                </StyledHeading>
              </StyledParagraph>
              <StyledParagraph>
                {texts[local].a_more.map((text) => (
                  <StyledInnerWrapper>
                    <StyledCreatorText>{text}</StyledCreatorText>
                  </StyledInnerWrapper>
                ))}
              </StyledParagraph>
              <StyledParagraph>
                <StyledSpan>Contact</StyledSpan>
                <StyledLink>folksongsofukraine@gmail.com</StyledLink>
              </StyledParagraph>
              <StyledLogo src={`/img/png/logo_white_${local}.png`} />
            </StyledContainer>
          </StyledBackdrop>
        </div>
      </StyledAboutImage>
    </>
  )
}
