import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import songsAPI from 'api/songsAPI'

export const getGenres = createAsyncThunk(
  'genres/getGenres',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().genres.genres) {
      return thunkAPI.getState().genres.genres
    }
    const genres = await songsAPI.fetchGenres()
    return genres.data
  }
)

export const getGenresStructured = createAsyncThunk(
  'genres/getGenresStructured',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().genres.genresStructured) {
      return thunkAPI.getState().genres.genresStructured
    }
    const genres = await songsAPI.fetchGenres()
    return genres.data.reduce(
      (acc, genre) => ({ ...acc, [genre.id]: genre }),
      {}
    )
  }
)

export const getGenreById = createAsyncThunk(
  'genres/getGenreById',
  async (id) => {
    const genre = await songsAPI.fetchGenreById(id)
    return genre.data
  }
)

const genresSlice = createSlice({
  name: 'genres',
  initialState: { genres: null, genresStructured: null, currentGenre: null },
  reducers: {},
  extraReducers: {
    [getGenres.fulfilled]: (state, { payload }) => {
      state.genres = payload
    },
    [getGenresStructured.fulfilled]: (state, { payload }) => {
      state.genresStructured = payload
    },
    [getGenreById.fulfilled]: (state, { payload }) => {
      state.currentGenre = payload
    },
  },
})

export const genresReducer = genresSlice.reducer
