import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import * as media from 'styles/mixins'

const StyledLogoWrapper = styled.div``
const StyledSiteName = styled.h1``
const StyledLogo = styled.img`
  ${media.desktopM} {
    max-height: 34px;
  }
`

export default function Logo() {
  const logo = useSelector(({ language }) => {
    if (language.local === 'eng') {
      return '/img/png/logo_eng.png'
    }
    return '/img/png/logo.png'
  })

  return (
    <StyledLogoWrapper>
      <StyledSiteName>
        <StyledLogo src={logo} />
      </StyledSiteName>
    </StyledLogoWrapper>
  )
}
