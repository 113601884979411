import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import MobileMenu from 'components/MobileMenu/MobileMenu'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import ModalWithButton from 'components/Modal/ModalWithButton'
import { getGenreById } from 'redux/genresSlice'
import { getRegionById, resetCurrentRegion } from 'redux/regionsSlice'
import { desktopM } from 'styles/mixins'
import Iframe from 'components/Iframe/Iframe'
import SearchMenu from 'components/SearchMenu/SearchMenu'
import SearchBar from 'components/SearchBar/SearchBar'

const StyledBackground = styled.div`
  background-position: center bottom;
  background-size: cover;
  position: relative;
  z-index: 3;
  width: 100%;
  height: calc(100vh - 80px);

  ${desktopM} {
    height: calc(100vh - 50px);
  }
`

const StyledClickDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 7;
`

export default function GenreScreen() {
  const dispatch = useDispatch()
  const isMobileMenuOpen = useSelector((state) => state.mobileMenu)
  const isSearchMenuOpen = useSelector((state) => state.searchMenu)

  const [openedList, setOpenedList] = useState('')

  const genre = useParams().genre
  const region = useParams().region

  useEffect(() => {
    if (genre) {
      dispatch(getGenreById(genre))
    }
    if (region) {
      dispatch(getRegionById(region))
    } else {
      dispatch(resetCurrentRegion())
    }
  }, [dispatch, genre, region])

  const currentGenre = useSelector((state) => state.genres.currentGenre)
  const currentRegion = useSelector((state) => state.regions.currentRegion)

  return (
    <>
      {isMobileMenuOpen && <MobileMenu />}
      {isSearchMenuOpen && <SearchMenu />}
      {currentGenre && (
        <StyledBackground
          style={{
            backgroundImage: `url(/img/jpg/${genre}_BG.jpg)`,
          }}
        >
          <Breadcrumbs region={currentRegion} genre={currentGenre} />
          <SearchBar openedList={openedList} setOpenedList={setOpenedList} />
          {openedList && <StyledClickDiv onClick={() => setOpenedList('')} />}
          <ModalWithButton genres={genre} ethnic_regions={region} />
          <Iframe src={`/animations/${genre}/${genre}.html`} />
        </StyledBackground>
      )}
    </>
  )
}
