export function handleCheck(array, id, setFieldValue, fieldName) {
  const convertedArray = array.map((item) => {
    if (item.id !== id) {
      return item
    }
    return { ...item, checked: !item.checked }
  })
  setFieldValue(fieldName, convertedArray)
}

export function clearSelect(array, setFieldValue, fieldName) {
  const convertedArray = array.map((item) => {
    item.checked = false
    return item
  })
  setFieldValue(fieldName, convertedArray)
}
