import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { PersistGate } from 'redux-persist/integration/react'
import 'reset-css'
import './fonts/InnerspaceRegular.ttf'
import './fonts/LatoRegular.ttf'
import './index.css'
import App from './App'
import { store, persistor } from './redux/store'
import { CookiesProvider } from 'react-cookie'

const history = createBrowserHistory()

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <PersistGate persistor={persistor}>
        <Provider store={store}>
          <Router history={history}>
            <App />
          </Router>
        </Provider>
      </PersistGate>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
