import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import * as media from 'styles/mixins'

const StyledListItem = styled.div`
  width: 82px;
  margin-bottom: 14px;

  ${media.mobile} {
    width: 100px;
  }
`

const StyledLink = styled(Link)`
  textdecoration: none;
`

const StyledImageWrapper = styled.div`
  height: 34px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  transition: all 0.25s ease;

  &:hover {
    transform: scale(1.5);
  }
`

const StyledImage = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  max-height: 132px;
`

const StyledName = styled.button`
  font-family: 'Innerspace', serif;
  color: #fff;
  text-transform: uppercase;
  font-size: 9px;
  display: block;
  margin: 0 auto;
  padding: 4px;
  border: none;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: all 0.25s ease;
  word-spacing: 8px;

  &:first-letter {
    font-size: 14px;
  }
`

export default function GenresBarItemSimple(props) {
  return (
    <StyledListItem>
      <StyledLink
        to={
          props.region
            ? `/regions/${props.region}/genres/${props.link}`
            : `/genres/${props.link}`
        }
      >
        <StyledImageWrapper>
          <StyledImage
            src={`${process.env.REACT_APP_CMS}${props.image.url}`}
            alt={props.name}
          />
        </StyledImageWrapper>
        <StyledName>{props.name}</StyledName>
      </StyledLink>
    </StyledListItem>
  )
}
