export default {
  songs_main: 'National songs of Ukraine',
  about: 'About project',
  genres: 'Genres',
  genre: 'Genre',
  regions: 'Regions',
  region: 'Region',
  state: "State (Oblast')",
  all_songs: 'Songs',
  songs: 'Songs',
  all: 'All genres',
  text: 'Text',
  translation: 'Translation',
  notes: 'Music sheet',
  video: 'Video',
  partners: 'Partners',
  copyright: '(c) Folk songs of Ukraine',
  all_genres: 'All genres',
  player: 'Play',
  now_on_site: 'Now on site',
  clear_list: 'clear list',
  about_text:
    'The main purpose of the big Archive of Folk Songs Project is to collect, categorize and give universal online accessibility to unique examples of the folk songs of Ukraine. It will consist mainly of music pieces garnered from private collections of folklorists and folklore centres engaged in collecting such music since the 1970’s.',
  no_songs_text: 'Nothing found due to your request.',
  no_songs_try: 'Try again!',
  Chernigiv: 'Chernigivschyna',
  Western_Polissia: 'Western Polissia',
  Middle_Polissia: 'Middle Polissia',
  Eastern_Polissia: 'Eastern Polissia',
  Poltavshchyna: 'Poltavshchyna',
  Slobozhanshchyna: 'Slobozhanshchyna',
  Naddniprianshchyna: 'Naddniprianshchyna',
  Steppe: 'Steppe',
  Sea_side: 'Sea side',
  Tavria: 'Tavria',
  Bukovyna: 'Bukovyna',
  Podillya: 'Podillya',
  Karpaty: 'Karpaty',
  Halychyna: 'Halychyna',
  Zakarpattya: 'Zakarpattya',
  allUkraine: 'All Ukraine',
  no_songs: 'There are no songs due to your request',
  search: 'Search',
  search_act: 'Search',
  search_full: 'All',
  a_intro: [
    "I'm a musician.",
    'And the meaning of my existence in the world - is to give people Joy and Happiness.',
    'Digital archive Folk songs of Ukraine - about Joy and Love.',
    'Love to my country.',
    'Love to tradition.',
    'And incredibly strong desire - to share my love to folk songs around the world.',
    'A sincere “Thank You” and a level of deep respect goes out to the authentical folk singers.',
    'To all people dedicated to the preservation of our ancient sung history.',
    'Thank you to my family, to the partners and supporters for your inspiration.',
    'To many others around the world helped in the creation of the website: Ukrainian folklorists, artists, designers, museum curators, photographers, collectors and owners of private archives.',
    'Project was created with the support of Ukrainian-Canadian Research & Documentation Center.',
  ],
  a_act: [
    'Listen.',
    'Sing.',
    'Learn.',
    'Enjoy.',
    'Fall in love with these beautiful songs.',
  ],
  a_sign: 'Marichka',
  a_heading: 'ABOUT THE CREATOR',
  a_more: [
    'Marichka Marczyk is an ethnomusicologist and singer with the Ukrainian Canadian musical groups – Balaklava Blues and Lemon Bucket Orkestra. She co-founded and sang in the “Bozychi” folk ensemble.',
    'She completed a degree in Ethnomusicology from the National Academy of Music in Ukraine.',
    'Together with her husband Mark Marczyk, she is known for their creation and performance of the Folk-opera “Counting Sheep”, has been written about by "The New York Times", "The Guardian", "Toronto Star" etc.',
  ],
}
