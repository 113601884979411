import styled from 'styled-components'
import { lowScreen, tabletM } from 'styles/mixins'

export const StyledWrapper = styled.div`
  display: none;
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.8);
  background-position: center center;
  background-size: contain;

  transition: all 0.5s ease;

  ${tabletM} {
    display: block;
  }
`

export const StyledContainer = styled.div`
  height: 100%;
  padding: 44px 30px;
  position: relative;

  ${lowScreen} {
    padding: 12px;
  }
`

export const StyledForm = styled.form`
  width: 100%;
`

export const StyledSearchInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 0 20px;
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  color: #fff;
  margin-bottom: 68px;

  &::placeholder {
    font-family: 'Innerspace', serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
  }
`

export const StyledSelectBlock = styled.div`
  margin-bottom: 68px;
`

export const StyledSelectButton = styled.button`
  margin: 0 auto;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
`

export const StyledSelectLabel = styled.p`
  font-family: 'Innerspace', serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin-right: 14px;
`

export const StyledArrow = styled.img`
  width: 32px;
  height: 32px;
`

export const StyledCheckboxBlock = styled.div`
  position: absolute;
  top: 114px;
  bottom: 100px;
  left: 30px;
  right: 30px;
  background-color: #fff;
  border-radius: 6px;
  padding: 36px;
  z-index: 299;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledCheckboxList = styled.ul`
  max-height: calc(100vh - 440px);
  overflow-y: scroll;
  scrollbar-color: #353535 transparent;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #353535;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #353535;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`

export const StyledCheckboxContainer = styled.li`
  padding-left: 34px;
  margin-bottom: 22px;
`

export const StyledCheckboxWrapper = styled.div`
  padding-left: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 80px;
`

export const StyledCheckboxLabel = styled.label`
  font-family: 'Innerspace', serif;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  position: relative;

  &:not(:last-of-type) {
    margin-right: 14px;
  }

  &::before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: -33px;
    transform: translateY(-50%);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`

export const StyledTransparentButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  margin-bottom: 20px;
`

export const StyledCheckbox = styled.input`
  display: none;

  & + label::before {
    background-image: url('/img/png/radio_disabled.png');
  }

  &:checked + label::before {
    background-image: url('/img/png/radio_enabled.png');
  }
`

export const StyledDarkCheckboxLabel = styled.label`
  font-family: 'Innerspace', serif;
  text-transform: capitalize;
  font-size: 14px;
  cursor: pointer;
  position: relative;

  &:not(:last-of-type) {
    margin-right: 14px;
  }

  &::before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: -33px;
    transform: translateY(-50%);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`

export const StyledDarkCheckbox = styled.input`
  display: none;

  & + label::before {
    background-image: url('/img/png/radio_disabled_dark.png');
  }

  &:checked + label::before {
    background-image: url('/img/png/radio_enabled_dark.png');
  }
`

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 46px;
  margin: 0 auto;
  background: url('/img/png/search_button_background.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
  outline: none;
  opacity: 1;
  color: #1b100e;
  cursor: pointer;
`

export const StyledSearchGlass = styled.img`
  width: 15px;
  height: 16px;
  margin-right: 16px;
`

export const StyledOKButton = styled.button`
  width: 88px;
  height: 45px;
  background: url('/img/png/ok_button.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`
