import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import songsAPI from 'api/songsAPI'

export const getRegions = createAsyncThunk(
  'regions/getRegions',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().regions.regions) {
      return thunkAPI.getState().regions.regions
    }
    const regions = await songsAPI.fetchRegions()
    return regions.data.reduce(
      (acc, region) => ({ ...acc, [region.id]: region }),
      {}
    )
  }
)

export const getRegionById = createAsyncThunk(
  'regions/getRegionById',
  async (id) => {
    const region = await songsAPI.fetchRegionById(id)
    return region.data
  }
)

const regionsSlice = createSlice({
  name: 'regions',
  initialState: { regions: null, currentRegion: null },
  reducers: {
    resetCurrentRegion(state, action) {
      state.currentRegion = null
    },
  },
  extraReducers: {
    [getRegions.fulfilled]: (state, { payload }) => {
      state.regions = payload
    },
    [getRegionById.fulfilled]: (state, { payload }) => {
      state.currentRegion = payload
    },
  },
})

export const { resetCurrentRegion } = regionsSlice.actions

export const regionsReducer = regionsSlice.reducer
