import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccordionSection } from './AccordionSection'
import { setOpenSections } from 'redux/accordionSlice'

const Accordion = ({ children }) => {
  const dispatch = useDispatch()
  const { openSections } = useSelector((state) => state.accordion)

  const onClick = (title) => {
    const isOpen = !!openSections[title]
    dispatch(
      setOpenSections({
        [title]: !isOpen,
      })
    )
  }

  return (
    <>
      {children.map((child) => (
        <AccordionSection
          isOpen={!!openSections[child.props.title]}
          onClick={onClick}
          song={child.props.song}
          key={child.props.song.id}
          index={child.props.index}
          setCurrentSong={child.props.setCurrentSong}
          scrollToTop={child.props.scrollToTop}
        >
          {child.props.children}
        </AccordionSection>
      ))}
    </>
  )
}

export default Accordion
