import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { regionsReducer } from './regionsSlice'
import { genresReducer } from './genresSlice'
import { songsReducer } from './songsSlice'
import { errorsReducer } from './errorsSlice'
import { loadingReducer } from './loadingSlice'
import languageReducer from './languageSlice'
import { accordionReducer } from './accordionSlice'
import { mobileMenuReducer } from './mobileMenuSlice'
import { searchMenuReducer } from './searchMenuSlice'
import { statesReducer } from './statesSlice'

const langConfig = {
  key: 'language',
  storage,
  whitelist: ['local'],
  serialize: true,
}

const rootReducer = combineReducers({
  regions: regionsReducer,
  genres: genresReducer,
  states: statesReducer,
  songs: songsReducer,
  errors: errorsReducer,
  loading: loadingReducer,
  accordion: accordionReducer,
  language: persistReducer(langConfig, languageReducer),
  mobileMenu: mobileMenuReducer,
  searchMenu: searchMenuReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

export const persistor = persistStore(store)
