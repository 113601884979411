import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import remark from 'remark'
import recommended from 'remark-preset-lint-recommended'
import html from 'remark-html'

import MobileMenu from 'components/MobileMenu/MobileMenu'
import * as media from 'styles/mixins'
import Iframe from 'components/Iframe/Iframe'
import songsAPI from 'api/songsAPI'

const StyledPartnersImage = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 80px);
  background-image: url('./img/jpg/Partner_BG.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

  ${media.desktopM} {
    min-height: calc(100vh - 50px);
  }
`

const StyledBackdrop = styled.div`
  position: relative;
  z-index: 10;
  width: 50%;
  max-height: calc(100vh - 80px);
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 30px 60px;
  scrollbar-color: #fff transparent;
  scrollbar-width: thin;

  ${media.desktopM} {
    min-height: calc(100vh - 50px);
  }

  ${media.tabletM} {
    width: 100%;
    padding: 30px;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ${media.tabletM} {
    width: 100%;
  }
`

const StyledContainer = styled.div``

const StyledParagraph = styled.div`
  margin-bottom: 40px;
`

const StyledLogo = styled.img`
  padding: 20px 0px;
`

export default function PartnersScreen() {
  const isMobileMenuOpen = useSelector((state) => state.mobileMenu)
  const local = useSelector((state) => state.language.local)

  const [partners, setPartners] = useState('')

  useEffect(() => {
    songsAPI.fetchPartners().then(({ data }) => {
      remark()
        .use(recommended)
        .use(html)
        .process(data.text_ukr, function (_, file) {
          data.text_ukr = String(file)
        })

      remark()
        .use(recommended)
        .use(html)
        .process(data.text_eng, function (_, file) {
          data.text_eng = String(file)
        })

      setPartners(data)
    })
  }, [])

  return (
    <>
      {partners && local && (
        <>
          {isMobileMenuOpen && <MobileMenu />}
          <StyledPartnersImage>
            <div className="container partners">
              <Iframe
                src="/animations/partners/Partners_animation.html"
                flex={true}
              />
              <StyledBackdrop>
                <StyledContainer>
                  <StyledParagraph
                    dangerouslySetInnerHTML={{
                      __html: partners[`text_${local}`],
                    }}
                  ></StyledParagraph>
                  <StyledLogo src={`/img/png/logo_white_${local}.png`} />
                </StyledContainer>
              </StyledBackdrop>
            </div>
          </StyledPartnersImage>
        </>
      )}
    </>
  )
}
