import React, { useEffect, useState, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { searchSongs, getSongs } from 'redux/songsSlice'
import { resetSongs } from 'redux/songsSlice'
import { setIsPlayerMinimized, setOpenSections } from 'redux/accordionSlice'
import Accordion from '../Accordion'
import Player from '../Player/Player'
import SongInfo from '../SongInfo/SongInfo'
import {
  StyledBackdrop,
  StyledModalWrapper,
  StyledModal,
  StyledList,
  StyledWoodenEdgeTop,
  StyledWoodenEdgeBottom,
  StyledButtonsWrapper,
  StyledButton,
  StyledImage,
  StyledEmptyWrapper,
  StyledBoldText,
  StyledText,
} from './styled'
import { getLocalized } from '../../utils/getLocalized'

export default function Modal({
  genres,
  ethnic_regions,
  regions,
  search,
  notes,
  video,
  setOpenedList,
}) {
  const dispatch = useDispatch()
  const location = useLocation()
  const songsListRef = createRef()

  const [currentSong, setCurrentSong] = useState(null)
  const [prevSongTitle, setPrevSongTitle] = useState('')
  const [nextSongTitle, setNextSongTitle] = useState('')

  useEffect(() => {
    const params = {
      genres,
      ethnic_regions,
      regions,
      text: search,
      notes,
      video,
    }

    if (
      genres.length === 0 &&
      ethnic_regions.length === 0 &&
      regions.length === 0 &&
      !search &&
      !notes &&
      !video
    ) {
      dispatch(getSongs(params))
    } else {
      dispatch(searchSongs(params))
      dispatch(setIsPlayerMinimized(false))
    }

    return () => {
      dispatch(resetSongs())
      dispatch(setIsPlayerMinimized(true))
    }

    // eslint-disable-next-line
  }, [dispatch, location.search])

  const changeState = () => {
    dispatch(setIsPlayerMinimized(!isPlayerMinimized))
  }

  const { isPlayerMinimized, openSections } = useSelector(
    (state) => state.accordion
  )
  const songs = useSelector((state) => state.songs.songs)
  const local = useSelector((state) => state.language.local)
  const loading = useSelector((state) => state.songs.loading)

  useEffect(() => {
    if (songs.length && local) {
      dispatch(setOpenSections({ [getLocalized(songs[0], 'name')]: true }))
    }
  }, [songs, dispatch, local])

  useEffect(() => {
    if (songs.length === 0) {
      return
    }
    if (Object.keys(openSections).length > 0) {
      songs.forEach((song, index, array) => {
        if (getLocalized(song, 'name') === Object.keys(openSections)[0]) {
          setPrevSongTitle(
            array[index - 1] ? getLocalized(array[index - 1], 'name') : ''
          )
          setNextSongTitle(
            array[index + 1] ? getLocalized(array[index + 1], 'name') : ''
          )
          setCurrentSong(song)
        }
      })
      return
    }

    setCurrentSong(songs[0])
    setPrevSongTitle('')
    setNextSongTitle(getLocalized(songs[1], 'name'))
  }, [songs, openSections, local])

  const scrollToTop = (index) => {
    songsListRef.current.scroll({ top: index * 80, behavior: 'smooth' })
  }

  return (
    <>
      <StyledBackdrop onClick={() => setOpenedList('')}>
        {!loading && (
          <StyledModalWrapper>
            <StyledWoodenEdgeTop />

            <Player
              song={currentSong}
              prevSongTitle={prevSongTitle}
              nextSongTitle={nextSongTitle}
            >
              {isPlayerMinimized && songs.length > 0 && (
                <StyledButtonsWrapper>
                  <StyledButton onClick={changeState}>
                    <StyledImage src="/img/svg/control_down.svg" />
                  </StyledButton>
                </StyledButtonsWrapper>
              )}
              {!isPlayerMinimized && songs.length > 0 && (
                <StyledButtonsWrapper>
                  <StyledButton onClick={changeState}>
                    <StyledImage src="/img/svg/control_up.svg" />
                  </StyledButton>
                </StyledButtonsWrapper>
              )}
            </Player>

            <StyledModal ref={songsListRef}>
              <>
                {songs.length > 0 && (
                  <StyledList
                    style={{
                      background: isPlayerMinimized ? 'transparent' : '#fff',
                    }}
                  >
                    <Accordion>
                      {songs &&
                        songs.map((song, index, array) => (
                          <div
                            key={song.id}
                            song={song}
                            title={getLocalized(song, 'name')}
                            index={index}
                            setCurrentSong={setCurrentSong}
                            scrollToTop={scrollToTop}
                          >
                            <SongInfo song={currentSong} />
                          </div>
                        ))}
                    </Accordion>
                  </StyledList>
                )}
                {songs.length === 0 && (
                  <StyledEmptyWrapper>
                    <div>
                      <StyledText>
                        <FormattedMessage id="no_songs_text" />
                      </StyledText>
                      <StyledBoldText>
                        <FormattedMessage id="no_songs_try" />
                      </StyledBoldText>
                    </div>
                  </StyledEmptyWrapper>
                )}
              </>
            </StyledModal>
            <StyledWoodenEdgeBottom />
          </StyledModalWrapper>
        )}
      </StyledBackdrop>
    </>
  )
}
