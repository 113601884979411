import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import { useFormik, FormikProvider, FieldArray } from 'formik'

import languages from 'languages'
import { handleCheck, clearSelect } from 'utils/searchArraysUtil'
import { getGenres } from 'redux/genresSlice'
import { getRegions } from 'redux/regionsSlice'
import { getStates } from 'redux/statesSlice'
import { toggleSearchMenu } from 'redux/searchMenuSlice'
import {
  StyledContainer,
  StyledSearchWrapper,
  StyledSearch,
  StyledIcon,
  StyledSearchText,
  StyledBarWrapper,
  StyledForm,
  StyledSearchInput,
  StyledInputWrapper,
  StyledSelectButton,
  StyledSelectLabel,
  StyledCheckbox,
  StyledCheckboxLabel,
  StyledCheckboxWrapper,
  StyledCheckboxBlock,
  StyledCheckboxList,
  StyledCheckboxContainer,
  StyledTransparentButton,
  StyledTransparentWhiteButton,
  StyledDarkCheckbox,
  StyledOKButton,
  StyledDarkCheckboxLabel,
  StyledDropdownWrapper,
  StyledButton,
  StyledCloseButton,
  StyledInfoWrapper,
  StyledSubheadingText,
  StyledInfoText,
} from './styled'

export default function SearchBar({
  selectedGenres,
  selectedEthnic_regions,
  selectedRegions,
  search,
  notes,
  video,
  openedList,
  setOpenedList,
}) {
  const dispatch = useDispatch()
  const history = useHistory()

  // const [openedList, setOpenedList] = useState('')

  const convertedRegions = useRef(null)
  const convertedStates = useRef(null)
  const convertedGenres = useRef(null)
  const [selectedRegionsString, setSelectedRegionsString] = useState(null)
  const [selectedStatesString, setSelectedStatesString] = useState(null)
  const [selectedGenresString, setSelectedGenresString] = useState(null)

  const isSearchMenuOpen = useSelector((state) => state.searchMenu)
  const language = useSelector((state) => state.language.local)

  useEffect(() => {
    dispatch(getGenres())
    dispatch(getRegions())
    dispatch(getStates())
  }, [dispatch])

  const genres = useSelector((state) => state.genres.genres)
  const regions = useSelector((state) => state.regions.regions)
  const states = useSelector((state) => state.states.states)

  useEffect(() => {
    convertedGenres.current = genres?.map((genre) => ({
      ...genre,
      checked: selectedGenres?.find((g) => g === genre.id) ? true : false,
    }))
    convertedRegions.current = regions
      ? Object.values(regions).map((region) => ({
          ...region,
          checked: selectedEthnic_regions?.find((r) => r === region.id)
            ? true
            : false,
        }))
      : []
    convertedStates.current = states?.map((state) => ({
      ...state,
      checked: selectedRegions?.find((s) => s === state.id) ? true : false,
    }))
    setSelectedRegionsString(
      convertedRegions.current
        ?.filter((region) => region.checked)
        .map((region) => region[`name_${language}`])
        .join(', ')
    )
    setSelectedStatesString(
      convertedStates.current
        ?.filter((state) => state.checked)
        .map((state) => state[`name_${language}`])
        .join(', ')
    )
    setSelectedGenresString(
      convertedGenres.current
        ?.filter((genre) => genre.checked)
        .map((genre) => genre[`name_${language}`])
        .join(', ')
    )
  }, [
    genres,
    regions,
    states,
    selectedGenres,
    selectedEthnic_regions,
    selectedRegions,
    language,
    convertedRegions,
    convertedStates,
    convertedGenres,
  ])

  const isSearchActive = useMemo(
    () =>
      selectedGenres?.length > 0 ||
      selectedEthnic_regions?.length > 0 ||
      selectedRegions?.length > 0 ||
      search?.length > 0 ||
      notes ||
      video,
    [
      notes,
      search,
      selectedEthnic_regions,
      selectedGenres,
      selectedRegions,
      video,
    ]
  )

  useEffect(() => {
    if (isSearchActive) {
      openSearchMenu()
    } else {
      closeSearchMenu()
    }
    return () => closeSearchMenu()
    // eslint-disable-next-line
  }, [])

  const formik = useFormik({
    initialValues: {
      search: search ? search : '',
      regions: convertedRegions.current,
      states: convertedStates.current,
      genres: convertedGenres.current,
      notes: !!notes,
      video: !!video,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      // closeSearchMenu()
      submitSearch(values)
    },
  })

  function openSearchMenu() {
    dispatch(toggleSearchMenu(true))
  }

  function closeSearchMenu() {
    dispatch(toggleSearchMenu(false))
  }

  const clearAllFields = () => {
    history.push()
  }

  const getValueIDs = (array) =>
    array.filter((item) => item.checked).map((item) => item.id)

  function submitSearch(values) {
    const searchParams = new URLSearchParams()
    if (values.search) {
      searchParams.set('search', values.search)
    }
    if (values.notes) {
      searchParams.set('notes', values.notes)
    }
    if (values.video) {
      searchParams.set('video', values.video)
    }

    const regionsIDs = getValueIDs(values.regions)
    const statessIDs = getValueIDs(values.states)
    const genresIDs = getValueIDs(values.genres)

    regionsIDs.forEach((id) => searchParams.append('ethnic_region', id))
    statessIDs.forEach((id) => searchParams.append('region', id))
    genresIDs.forEach((id) => searchParams.append('genre', id))

    history.push({
      pathname: '/songs',
      search: `?${searchParams}`,
    })
  }

  return (
    <>
      {!isSearchMenuOpen && (
        <StyledSearchWrapper>
          <StyledSearch onClick={openSearchMenu}>
            <StyledIcon src="/img/svg/icon_search.svg" alt="open search menu" />
            <StyledSearchText>
              <FormattedMessage id="search" />
            </StyledSearchText>
          </StyledSearch>
        </StyledSearchWrapper>
      )}
      {isSearchMenuOpen && (
        <StyledContainer>
          <StyledBarWrapper>
            <FormikProvider value={formik}>
              <StyledForm onSubmit={formik.handleSubmit} autoComplete="off">
                <StyledInputWrapper>
                  <StyledIcon
                    src="/img/svg/icon_search.svg"
                    alt="open search menu"
                  />
                  <StyledSearchInput
                    type="text"
                    name="search"
                    id="search"
                    autoComplete="off"
                    value={formik.values.search}
                    placeholder={languages[language].search_full}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </StyledInputWrapper>

                <StyledDropdownWrapper>
                  <StyledSelectButton
                    type="button"
                    onClick={() => setOpenedList('regions')}
                  >
                    <StyledSelectLabel>
                      <FormattedMessage id="region" />
                    </StyledSelectLabel>
                    <StyledIcon src="/img/svg/control_down.svg" />
                  </StyledSelectButton>
                  <>
                    {openedList === 'regions' && formik.values.regions && (
                      <StyledCheckboxBlock>
                        <FieldArray
                          name="regions"
                          id="regions"
                          render={() => (
                            <StyledCheckboxList>
                              {formik.values.regions.map((region) => (
                                <StyledCheckboxContainer key={region.id}>
                                  <StyledDarkCheckbox
                                    type="checkbox"
                                    name={region[`name_${language}`]}
                                    id={region[`name_${language}`]}
                                    checked={region.checked}
                                    onChange={() =>
                                      handleCheck(
                                        formik.values.regions,
                                        region.id,
                                        formik.setFieldValue,
                                        'regions'
                                      )
                                    }
                                  />
                                  <StyledDarkCheckboxLabel
                                    htmlFor={region[`name_${language}`]}
                                  >
                                    {region[`name_${language}`]}
                                  </StyledDarkCheckboxLabel>
                                </StyledCheckboxContainer>
                              ))}
                            </StyledCheckboxList>
                          )}
                        />
                        <div>
                          {getValueIDs(formik.values.regions).length > 0 && (
                            <StyledTransparentButton
                              type="button"
                              onClick={() =>
                                clearSelect(
                                  formik.values.regions,
                                  formik.setFieldValue,
                                  'regions'
                                )
                              }
                            >
                              <FormattedMessage id="clear_list" />
                            </StyledTransparentButton>
                          )}
                          <StyledOKButton
                            type="button"
                            onClick={() => setOpenedList('')}
                          >
                            OK
                          </StyledOKButton>
                        </div>
                      </StyledCheckboxBlock>
                    )}
                  </>
                </StyledDropdownWrapper>

                <StyledDropdownWrapper>
                  <StyledSelectButton
                    type="button"
                    onClick={() => setOpenedList('states')}
                  >
                    <StyledSelectLabel>
                      <FormattedMessage id="state" />
                    </StyledSelectLabel>
                    <StyledIcon src="/img/svg/control_down.svg" />
                  </StyledSelectButton>
                  <>
                    {openedList === 'states' && formik.values.states && (
                      <StyledCheckboxBlock>
                        <FieldArray
                          name="states"
                          id="states"
                          render={() => (
                            <StyledCheckboxList>
                              {formik.values.states.map((state) => (
                                <StyledCheckboxContainer key={state.id}>
                                  <StyledDarkCheckbox
                                    type="checkbox"
                                    name={state[`name_${language}`]}
                                    id={state[`name_${language}`]}
                                    checked={state.checked}
                                    onChange={() =>
                                      handleCheck(
                                        formik.values.states,
                                        state.id,
                                        formik.setFieldValue,
                                        'states'
                                      )
                                    }
                                  />
                                  <StyledDarkCheckboxLabel
                                    htmlFor={state[`name_${language}`]}
                                  >
                                    {state[`name_${language}`]}
                                  </StyledDarkCheckboxLabel>
                                </StyledCheckboxContainer>
                              ))}
                            </StyledCheckboxList>
                          )}
                        />
                        <div>
                          {getValueIDs(formik.values.states).length > 0 && (
                            <StyledTransparentButton
                              type="button"
                              onClick={() =>
                                clearSelect(
                                  formik.values.states,
                                  formik.setFieldValue,
                                  'states'
                                )
                              }
                            >
                              <FormattedMessage id="clear_list" />
                            </StyledTransparentButton>
                          )}
                          <StyledOKButton
                            type="button"
                            onClick={() => setOpenedList('')}
                          >
                            OK
                          </StyledOKButton>
                        </div>
                      </StyledCheckboxBlock>
                    )}
                  </>
                </StyledDropdownWrapper>

                <StyledDropdownWrapper>
                  <StyledSelectButton
                    type="button"
                    onClick={() => setOpenedList('genres')}
                  >
                    <StyledSelectLabel>
                      <FormattedMessage id="genre" />
                    </StyledSelectLabel>
                    <StyledIcon src="/img/svg/control_down.svg" />
                  </StyledSelectButton>
                  <>
                    {openedList === 'genres' && formik.values.genres && (
                      <StyledCheckboxBlock>
                        <FieldArray
                          name="genres"
                          id="genres"
                          render={() => (
                            <StyledCheckboxList>
                              {formik.values.genres.map((genre) => (
                                <StyledCheckboxContainer key={genre.id}>
                                  <StyledDarkCheckbox
                                    type="checkbox"
                                    name={genre[`name_${language}`]}
                                    id={genre[`name_${language}`]}
                                    checked={genre.checked}
                                    onChange={() =>
                                      handleCheck(
                                        formik.values.genres,
                                        genre.id,
                                        formik.setFieldValue,
                                        'genres'
                                      )
                                    }
                                  />
                                  <StyledDarkCheckboxLabel
                                    htmlFor={genre[`name_${language}`]}
                                  >
                                    {genre[`name_${language}`]}
                                  </StyledDarkCheckboxLabel>
                                </StyledCheckboxContainer>
                              ))}
                            </StyledCheckboxList>
                          )}
                        />
                        <div>
                          {getValueIDs(formik.values.genres).length > 0 && (
                            <StyledTransparentButton
                              type="button"
                              onClick={() =>
                                clearSelect(
                                  formik.values.genres,
                                  formik.setFieldValue,
                                  'genres'
                                )
                              }
                            >
                              <FormattedMessage id="clear_list" />
                            </StyledTransparentButton>
                          )}
                          <StyledOKButton
                            type="button"
                            onClick={() => setOpenedList('')}
                          >
                            OK
                          </StyledOKButton>
                        </div>
                      </StyledCheckboxBlock>
                    )}
                  </>
                </StyledDropdownWrapper>

                <StyledCheckboxWrapper>
                  <StyledCheckbox
                    type="checkbox"
                    name="notes"
                    id="notes"
                    checked={formik.values.notes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <StyledCheckboxLabel htmlFor="notes">
                    <FormattedMessage id="notes" />
                  </StyledCheckboxLabel>
                  <StyledCheckbox
                    type="checkbox"
                    name="video"
                    id="video"
                    checked={formik.values.video}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <StyledCheckboxLabel htmlFor="video">
                    <FormattedMessage id="video" />
                  </StyledCheckboxLabel>
                </StyledCheckboxWrapper>

                <StyledButton type="submit">
                  <FormattedMessage id="search_act" />
                </StyledButton>
                <StyledCloseButton type="button" onClick={closeSearchMenu} />
              </StyledForm>
            </FormikProvider>
          </StyledBarWrapper>
          {isSearchActive && (
            <StyledInfoWrapper>
              <div>
                {selectedRegionsString?.length > 0 && (
                  <>
                    <StyledSubheadingText>
                      <FormattedMessage id="region" />:
                    </StyledSubheadingText>
                    <StyledInfoText>{selectedRegionsString}</StyledInfoText>
                  </>
                )}

                {selectedStatesString?.length > 0 && (
                  <>
                    <StyledSubheadingText>
                      <FormattedMessage id="state" />:
                    </StyledSubheadingText>
                    <StyledInfoText>{selectedStatesString}</StyledInfoText>
                  </>
                )}

                {selectedGenresString?.length > 0 && (
                  <>
                    <StyledSubheadingText>
                      <FormattedMessage id="genre" />:
                    </StyledSubheadingText>
                    <StyledInfoText>{selectedGenresString}</StyledInfoText>
                  </>
                )}
              </div>
              <StyledTransparentWhiteButton
                type="button"
                onClick={clearAllFields}
              >
                <FormattedMessage id="clear_list" />
              </StyledTransparentWhiteButton>
            </StyledInfoWrapper>
          )}
        </StyledContainer>
      )}
    </>
  )
}
