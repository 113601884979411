import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useCookies } from 'react-cookie'

import Footer from 'components/Footer/Footer'
import MobileMenu from 'components/MobileMenu/MobileMenu'
import { tabletM, desktopSM } from 'styles/mixins'
import Iframe from 'components/Iframe/Iframe'
import SearchMenu from 'components/SearchMenu/SearchMenu'
import SearchBar from 'components/SearchBar/SearchBar'
import songsAPI from 'api/songsAPI'

const StyledMainImage = styled.div`
  width: 100%;
  height: calc(100vh - 160px);
  background-image: url('./img/jpg/mainpage_BG.jpg');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 30;
  position: relative;

  ${desktopSM} {
    height: calc(100vh - 130px);
  }

  ${tabletM} {
    height: calc(100vh - 130px);
  }
`

const StyledSongsWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 14vw;
  height: 14vw;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 50vw;
    height: 50vw;
  }
`

const StyledInfoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14vw;
`

const StyledSongsHeading = styled.h3`
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
`

const StyledSongsQuantity = styled.h3`
  font-size: 26px;
  text-transform: lowercase;
  color: #fff;
  text-align: center;
`

const StyledClickDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 7;
`

export default function HomeScreen() {
  const isMobileMenuOpen = useSelector((state) => state.mobileMenu)
  const isSearchMenuOpen = useSelector((state) => state.searchMenu)
  const [songsQuantity, setSongsQuantity] = useState(0)
  const [cookie, setCookie] = useCookies(['songs_quantity'])
  const [openedList, setOpenedList] = useState('')

  useEffect(() => {
    if (cookie.songs_quantity) {
      setSongsQuantity(cookie.songs_quantity)
    } else {
      songsAPI.fetchSongsCount().then(({ data }) => {
        setSongsQuantity(data)
        setCookie('songs_quantity', data, { maxAge: 60 * 60 * 24 })
      })
    }
  }, [cookie, setCookie])

  return (
    <>
      {isMobileMenuOpen && <MobileMenu />}
      {isSearchMenuOpen && <SearchMenu />}
      <StyledMainImage>
        <SearchBar openedList={openedList} setOpenedList={setOpenedList} />
        <StyledClickDiv onClick={() => setOpenedList('')} />
        <StyledSongsWrapper>
          <StyledInfoWrapper>
            <StyledSongsHeading>
              <FormattedMessage id="now_on_site" />
            </StyledSongsHeading>
            <StyledSongsQuantity>
              {songsQuantity} <FormattedMessage id="songs" />
            </StyledSongsQuantity>
          </StyledInfoWrapper>
        </StyledSongsWrapper>
        <Iframe src={`/animations/main/mainpage_animation.html`} />
      </StyledMainImage>
      <Footer />
    </>
  )
}
