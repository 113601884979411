import { createSlice } from '@reduxjs/toolkit'

const searchMenuSlice = createSlice({
  name: 'searchMenu',
  initialState: false,
  reducers: {
    toggleSearchMenu: (_, action) => action.payload,
  },
})

export const { toggleSearchMenu } = searchMenuSlice.actions

export const searchMenuReducer = searchMenuSlice.reducer
