import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Media from 'react-media'

import Window from 'components/Window/Window'
import WindowOneSide from 'components/WindowOneSide/WindowOneSide'
import Door from 'components/Door/Door'
import MobileMenu from 'components/MobileMenu/MobileMenu'
import SearchMenu from 'components/SearchMenu/SearchMenu'
import SearchBar from 'components/SearchBar/SearchBar'
import * as media from 'styles/mixins'
import { getRegions } from 'redux/regionsSlice'
import { getLocalized } from 'utils/getLocalized'

const StyledBackground = styled.div`
  position: relative;
  background-image: url('./img/jpg/regions-background.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center bottom;
  max-width: 100%;
  height: calc(100vh - 80px);
  min-height: calc(100vh - 80px);
  max-height: -webkit-fill-available;
  overflow: hidden;

  ${media.mobile} {
    min-height: 686px;
  }

  ${media.tablet} {
    min-height: 710px;
  }

  ${media.desktopS} {
    min-height: 640px;
  }

  ${media.desktopM} {
    height: calc(100vh - 50px);
  }

  ${media.desktopL} {
    min-height: 780px;
  }

  ${media.desktopXl} {
    min-height: 936px;
    background-image: url('./img/jpg/regions-background.jpg');
  }
`

export const StyledClickDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
`

const StyledTreesBackground = styled.div`
  position: absolute;
  bottom: 74px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  width: 90%;
  max-width: 1706px;
  height: 788px;
  max-height: 550px;
  background-image: url('./img/png/trees-background.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  ${media.tablet} {
    background-image: url('./img/png/trees-background-m.png');
    bottom: 10vh;
    width: 100%;
    max-height: 788px;
    background-size: cover;
    background-position: center bottom;
  }

  ${media.desktopS} {
    background-image: url('./img/png/trees-background-l.png');
    background-size: contain;
    width: 90%;
  }

  ${media.desktopL} {
    bottom: 14vh;
  }

  ${media.desktopXl} {
    bottom: 16vh;
  }

  ${media.desktopXl} {
    bottom: 16vh;
  }
`

const StyledHouseCenter = styled.img`
  position: absolute;
  bottom: 9vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 7;
  width: 552px;
  height: 752px;
  max-height: 752px;

  ${media.desktopS} {
    height: 680px;
    max-height: 680px;
  }

  ${media.desktopL} {
    height: 820px;
    max-height: 820px;
    bottom: 10vh;
  }

  ${media.desktopXl} {
    width: 800px;
    max-width: 800px;
    height: 945px;
    max-height: 945px;
    bottom: 10vh;
  }

  ${media.desktopXl} {
    width: 800px;
    max-width: 800px;
    height: 945px;
    max-height: 945px;
    bottom: 10vh;
  }
`

const StyledHouseLeft = styled.img`
  position: absolute;
  bottom: 10vh;
  left: 50%;
  z-index: 5;
  width: 400px;
  height: 592px;
  max-height: 592px;
  transform: translateX(-550px);

  ${media.desktopS} {
    height: 530px;
    max-height: 530px;
  }

  ${media.desktopL} {
    width: 500px;
    height: 628px;
    max-height: 628px;
    transform: translateX(-628px);
  }

  ${media.desktopXl} {
    width: 665px;
    max-width: 665px;
    height: 745px;
    max-height: 745px;
    transform: translateX(-815px);
    bottom: 11vh;
  }

  ${media.desktopXl} {
    width: 665px;
    max-width: 665px;
    height: 745px;
    max-height: 745px;
    transform: translateX(-815px);
    bottom: 11vh;
  }
`

const StyledHouseRight = styled.img`
  position: absolute;
  bottom: 10vh;
  left: 50%;
  z-index: 5;
  width: 400px;
  height: 592px;
  max-height: 592px;
  transform: translateX(140px) scale(-1, 1);

  ${media.desktopS} {
    height: 530px;
    max-height: 530px;
  }

  ${media.desktopL} {
    width: 500px;
    height: 628px;
    max-height: 628px;
    transform: translate(140px) scale(-1, 1);
  }

  ${media.desktopXl} {
    width: 665px;
    max-width: 665px;
    height: 745px;
    max-height: 745px;
    transform: translate(162px) scale(-1, 1);
    bottom: 11vh;
  }

  ${media.desktopXl} {
    width: 665px;
    max-width: 665px;
    height: 745px;
    max-height: 745px;
    transform: translate(162px) scale(-1, 1);
    bottom: 11vh;
  }
`

const StyledGrass = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  height: 90px;
  width: 100vw;
  background-image: url('./img/png/grass.png');
  background-size: contain;

  ${media.tablet} {
    height: 11vh;
  }
`

const StyledGirl = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 15;
  width: 120px;
  transform: translateX(-300px);

  ${media.desktopS} {
    transform: translate(-312px, 20px) scale(1);
  }

  ${media.desktopL} {
    transform: translateX(-356px) scale(1.1);
  }

  ${media.desktopXl} {
    transform: translateX(-445px) scale(1.4);
  }

  ${media.desktopXl} {
    transform: translateX(-445px) scale(1.4);
  }
`

const StyledRoof = styled.img`
  position: absolute;
  top: 16px;
  left: 50%;
  width: 100vw;
  transform: translate(-50%, -50%);
  z-index: 12;

  ${media.mobileS} {
    top: -6px;
  }
`

const StyledHouseMobile = styled.div`
  position: absolute;
  top: 88px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #f9ebd7;

  ${media.mobileS} {
    top: 52px;
  }
`

const StyledKitten = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-30%);
  z-index: 14;
  width: 80px;
  bottom: 10vh;

  ${media.mobile} {
    top: 2%;
  }

  ${media.tablet} {
    transform: translate(-328%, -558px);
  }

  ${media.desktopS} {
    transform: translate(-340%, -503px);
  }

  ${media.desktopL} {
    transform: translate(-354%, -596px);
  }

  ${media.desktopXl} {
    transform: translate(-460%, -726px) scale(1.3);
  }
`

export default function RegionsScreen() {
  const isMobileMenuOpen = useSelector((state) => state.mobileMenu)
  const isSearchMenuOpen = useSelector((state) => state.searchMenu)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRegions())
  }, [dispatch])

  const [openedList, setOpenedList] = useState('')

  const regions = useSelector((state) => state.regions.regions)

  return (
    <>
      {isMobileMenuOpen && <MobileMenu />}
      {isSearchMenuOpen && <SearchMenu />}
      <StyledBackground>
        <StyledTreesBackground />
        <StyledClickDiv onClick={() => setOpenedList('')} />
        <SearchBar openedList={openedList} setOpenedList={setOpenedList} />
        <Media
          queries={{
            small: '(max-width: 767px)',
            large: '(min-width: 768px)',
          }}
        >
          {(matches) => (
            <>
              {matches.small && (
                <>
                  <StyledKitten src="/img/png/cat.png" />
                  <StyledRoof src="./img/png/roof-mobile.png" />
                  <StyledHouseMobile />
                </>
              )}
              {matches.large && (
                <>
                  <StyledHouseLeft src="./img/png/house-left.png" />
                  <StyledHouseCenter src="./img/png/house-center.png" />
                  <StyledHouseRight src="./img/png/house-left.png" />
                  <StyledKitten src="/img/png/cat.png" />
                </>
              )}
            </>
          )}
        </Media>

        {regions && !!Object.keys(regions).length > 0 && (
          <>
            <Window
              spritePositionBackX="-334px"
              spritePositionBackY="-154px"
              offsetY="-510"
              offsetX="-490"
              offsetTabletY="-529"
              offsetTabletX="-426"
              offsetMobileY="70%"
              offsetMobileX="5%"
              fullWidth="98px"
              fullHeight="124px"
              width="31px"
              height="75px"
              spritePositionLeftDoorX="-121px"
              spritePositionLeftDoorY="-556px"
              partsOffsetY="27.5%"
              partsOffsetX="18.5%"
              spritePositionRightDoorX="-121px"
              spritePositionRightDoorY="-556px"
              rightPartOffsetX="18.5%"
              regionName={getLocalized(
                regions['605200d33b958976010c59fc'],
                'name'
              )}
              id="605200d33b958976010c59fc"
              color="light"
              spritePositionInnerX="-190px"
              spritePositionInnerY="-10px"
              innerImgwidth="160px"
              innerImgHeight="143px"
            />
            <Window
              spritePositionBackX="-163px"
              spritePositionBackY="-154px"
              offsetY="-562"
              offsetX="-53"
              offsetTabletY="-585"
              offsetTabletX="-56"
              offsetMobileY="72%"
              offsetMobileX="40%"
              fullWidth="108px"
              fullHeight="124px"
              width="31px"
              height="78px"
              spritePositionLeftDoorX="-70px"
              spritePositionLeftDoorY="-556px"
              partsOffsetY="20%"
              partsOffsetX="16%"
              spritePositionRightDoorX="-70px"
              spritePositionRightDoorY="-556px"
              rightPartOffsetX="26%"
              regionName={getLocalized(
                regions['5fab006919842f7945fac0df'],
                'name'
              )}
              id="5fab006919842f7945fac0df"
              color="dark"
              spritePositionInnerX="-550px"
              spritePositionInnerY="-22px"
              innerImgwidth="160px"
              innerImgHeight="159px"
            />
            <Window
              spritePositionBackX="-564px"
              spritePositionBackY="-154px"
              offsetY="-512"
              offsetX="355"
              offsetTabletY="-528"
              offsetTabletX="311"
              offsetMobileY="70%"
              offsetMobileX="76%"
              fullWidth="70px"
              fullHeight="124px"
              width="25px"
              height="72px"
              spritePositionLeftDoorX="-656px"
              spritePositionLeftDoorY="-463px"
              partsOffsetY="30%"
              partsOffsetX="19%"
              spritePositionRightDoorX="-656px"
              spritePositionRightDoorY="-463px"
              rightPartOffsetX="15%"
              regionName={getLocalized(
                regions['5faaed5a19842f7945fac0de'],
                'name'
              )}
              id="5faaed5a19842f7945fac0de"
              color="light"
              spritePositionInnerX="-10px"
              spritePositionInnerY="-550px"
              innerImgwidth="160px"
              innerImgHeight="160px"
            />
            <Window
              spritePositionBackX="-564px"
              spritePositionBackY="-10px"
              offsetY="-325"
              offsetX="-580"
              offsetTabletY="-345"
              offsetTabletX="-488"
              offsetMobileY="36%"
              offsetMobileX="8%"
              fullWidth="72px"
              fullHeight="124px"
              width="36px"
              height="101px"
              spritePositionLeftDoorX="-656px"
              spritePositionLeftDoorY="-129px"
              partsOffsetY="17%"
              partsOffsetX="6%"
              spritePositionRightDoorX="-656px"
              spritePositionRightDoorY="-129px"
              rightPartOffsetX="10%"
              regionName={getLocalized(
                regions['5fab00cc19842f7945fac0e2'],
                'name'
              )}
              id="5fab00cc19842f7945fac0e2"
              color="dark"
              spritePositionInnerX="-196px"
              spritePositionInnerY="-336px"
              innerImgwidth="160px"
              innerImgHeight="196px"
            />
            <Window
              spritePositionBackX="-334px"
              spritePositionBackY="-10px"
              offsetY="-325"
              offsetX="-474"
              offsetTabletY="-343"
              offsetTabletX="-380"
              offsetMobileY="53%"
              offsetMobileX="74%"
              fullWidth="100px"
              fullHeight="124px"
              width="39px"
              height="99px"
              spritePositionLeftDoorX="-656px"
              spritePositionLeftDoorY="-10px"
              partsOffsetY="15%"
              partsOffsetX="15%"
              spritePositionRightDoorX="-656px"
              spritePositionRightDoorY="-10px"
              rightPartOffsetX="15%"
              regionName={getLocalized(
                regions['5fab00f319842f7945fac0e5'],
                'name'
              )}
              id="5fab00f319842f7945fac0e5"
              color="dark"
              spritePositionInnerX="-550px"
              spritePositionInnerY="-350px"
              innerImgwidth="160px"
              innerImgHeight="180px"
            />
            <WindowOneSide
              spritePositionBackX="-454px"
              spritePositionBackY="-298px"
              offsetY="-283"
              offsetX="-211"
              offsetTabletY="-291"
              offsetTabletX="-218"
              offsetMobileY="52%"
              offsetMobileX="8%"
              fullWidth="74px"
              fullHeight="124px"
              width="53px"
              height="91px"
              spritePositionLeftDoorX="-112px"
              spritePositionLeftDoorY="-442px"
              partsOffsetY="19"
              partsOffsetX="13.5%"
              regionName={getLocalized(
                regions['5fab00d719842f7945fac0e3'],
                'name'
              )}
              id="5fab00d719842f7945fac0e3"
              spritePositionInnerX="-550px"
              spritePositionInnerY="-160px"
              innerImgwidth="160px"
              innerImgHeight="180px"
            />
            <WindowOneSide
              spritePositionBackX="-454px"
              spritePositionBackY="-154px"
              offsetY="-283"
              offsetX="101"
              offsetTabletY="-292"
              offsetTabletX="72"
              offsetMobileY="55%"
              offsetMobileX="42%"
              fullWidth="83px"
              fullHeight="124px"
              width="62px"
              height="87px"
              spritePositionLeftDoorX="-368px"
              spritePositionLeftDoorY="-298px"
              partsOffsetY="20"
              partsOffsetX="18.5%"
              regionName={getLocalized(
                regions['5fab00e219842f7945fac0e4'],
                'name'
              )}
              id="5fab00e219842f7945fac0e4"
              spritePositionInnerX="-370px"
              spritePositionInnerY="-175px"
              innerImgwidth="160px"
              innerImgHeight="179px"
            />
            <Window
              spritePositionBackX="-178px"
              spritePositionBackY="-10px"
              offsetY="-328"
              offsetX="296"
              offsetTabletY="-348"
              offsetTabletX="203"
              offsetMobileY="38%"
              offsetMobileX="36%"
              fullWidth="136px"
              fullHeight="124px"
              width="35px"
              height="95px"
              spritePositionLeftDoorX="-656px"
              spritePositionLeftDoorY="-250px"
              partsOffsetY="29%"
              partsOffsetX="22%"
              spritePositionRightDoorX="-656px"
              spritePositionRightDoorY="-250px"
              rightPartOffsetX="26%"
              regionName={getLocalized(
                regions['5fab010719842f7945fac0e6'],
                'name'
              )}
              id={regions['5fab010719842f7945fac0e6'].id}
              color="dark"
              spritePositionInnerX="-370px"
              spritePositionInnerY="-354px"
              innerImgwidth="160px"
              innerImgHeight="160px"
            />
            <WindowOneSide
              spritePositionBackX="-10px"
              spritePositionBackY="-10px"
              offsetY="-318"
              offsetX="433"
              offsetTabletY="-344"
              offsetTabletX="350"
              offsetMobileY="37%"
              offsetMobileX="71%"
              fullWidth="134px"
              fullHeight="114px"
              width="82px"
              height="94px"
              spritePositionLeftDoorX="-10px"
              spritePositionLeftDoorY="-442px"
              partsOffsetY="21"
              partsOffsetX="22.5%"
              regionName={getLocalized(
                regions['5fab017119842f7945fac0ec'],
                'name'
              )}
              id="5fab017119842f7945fac0ec"
              spritePositionInnerX="-197px"
              spritePositionInnerY="-526px"
              innerImgwidth="160px"
              innerImgHeight="230px"
            />
            <WindowOneSide
              spritePositionBackX="-126px"
              spritePositionBackY="-298px"
              offsetY="-33"
              offsetX="-595"
              offsetTabletY="-54"
              offsetTabletX="-505"
              offsetMobileY="18%"
              offsetMobileX="5%"
              fullWidth="92px"
              fullHeight="124px"
              width="90px"
              height="124px"
              sideWidth="90%"
              spritePositionLeftDoorX="-454px"
              spritePositionLeftDoorY="-10px"
              partsOffsetY="4"
              partsOffsetX="3.5%"
              regionName={getLocalized(
                regions['5fab012919842f7945fac0e8'],
                'name'
              )}
              id="5fab012919842f7945fac0e8"
              spritePositionInnerX="-10px"
              spritePositionInnerY="22px"
              innerImgwidth="160px"
              innerImgHeight="188px"
            />
            <WindowOneSide
              spritePositionBackX="-10px"
              spritePositionBackY="-298px"
              offsetY="-20"
              offsetX="-246"
              offsetTabletY="-32"
              offsetTabletX="-216"
              offsetMobileY="3%"
              offsetMobileX="5%"
              fullWidth="96px"
              fullHeight="124px"
              width="59px"
              height="73px"
              spritePositionLeftDoorX="-185px"
              spritePositionLeftDoorY="-442px"
              partsOffsetY="36"
              partsOffsetX="21.5%"
              regionName={getLocalized(
                regions['5fab011919842f7945fac0e7'],
                'name'
              )}
              id="5fab011919842f7945fac0e7"
              spritePositionInnerX="-10px"
              spritePositionInnerY="-370px"
              innerImgwidth="160px"
              innerImgHeight="160px"
            />
            <Door
              spritePositionBackX="-296px"
              spritePositionBackY="-441px"
              offsetY="5"
              offsetX="-70px"
              offsetTabletY="1vh"
              offsetTabletX="-70px"
              offsetMobileY="0%"
              offsetMobileX="-33%"
              fullWidth="55px"
              fullHeight="72px"
            />
            <WindowOneSide
              spritePositionBackX="-10px"
              spritePositionBackY="-154px"
              offsetY="-17"
              offsetX="104"
              offsetTabletY="-27"
              offsetTabletX="88"
              offsetMobileY="18%"
              offsetMobileX="66%"
              fullWidth="133px"
              fullHeight="124px"
              width="110px"
              height="102px"
              spritePositionLeftDoorX="-238px"
              spritePositionLeftDoorY="-298px"
              partsOffsetY="9"
              partsOffsetX="8%"
              regionName={getLocalized(
                regions['605201063b958976010c59fd'],
                'name'
              )}
              id="605201063b958976010c59fd"
              spritePositionInnerX="-370px"
              spritePositionInnerY="-10px"
              innerImgwidth="160px"
              innerImgHeight="165px"
            />
            <WindowOneSide
              spritePositionBackX="-564px"
              spritePositionBackY="-298px"
              offsetY="-20"
              offsetX="370"
              offsetTabletY="-38"
              offsetTabletX="279"
              offsetMobileY="20%"
              offsetMobileX="43%"
              fullWidth="66px"
              fullHeight="124px"
              width="40px"
              height="95px"
              spritePositionLeftDoorX="-10px"
              spritePositionLeftDoorY="-556px"
              partsOffsetY="15"
              partsOffsetX="16%"
              regionName={getLocalized(
                regions['5fab015419842f7945fac0ea'],
                'name'
              )}
              id="5fab015419842f7945fac0ea"
              spritePositionInnerX="-10px"
              spritePositionInnerY="-172px"
              innerImgwidth="160px"
              innerImgHeight="168px"
            />
            <WindowOneSide
              spritePositionBackX="527px"
              spritePositionBackY="-530px"
              offsetY="-20"
              offsetX="510"
              offsetTabletY="-38"
              offsetTabletX="373"
              offsetMobileY="2%"
              offsetMobileX="72%"
              fullWidth="102px"
              fullHeight="124px"
              width="72px"
              height="93px"
              spritePositionLeftDoorX="388px"
              spritePositionLeftDoorY="-552px"
              partsOffsetY="16"
              partsOffsetX="17%"
              regionName={getLocalized(
                regions['5fab016419842f7945fac0eb'],
                'name'
              )}
              id="5fab016419842f7945fac0eb"
              spritePositionInnerX="-190px"
              spritePositionInnerY="-190px"
              innerImgwidth="160px"
              innerImgHeight="160px"
            />
          </>
        )}
        <Media
          query="(min-width: 768px)"
          render={() => (
            <>
              <StyledGirl src="./img/png/girl.png" />
              <StyledGrass />
            </>
          )}
        />
      </StyledBackground>
    </>
  )
}
