import { createSlice } from '@reduxjs/toolkit'

const accordionSlice = createSlice({
  name: 'accordion',
  initialState: {
    openSections: {},
    isPlayerMinimized: true,
  },
  reducers: {
    setOpenSections: (state, action) => {
      state.openSections = action.payload
    },
    setIsPlayerMinimized: (state, action) => {
      state.isPlayerMinimized = action.payload
    },
  },
})

export const { setOpenSections, setIsPlayerMinimized } = accordionSlice.actions

export const accordionReducer = accordionSlice.reducer
