import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as media from 'styles/mixins'

import { toggleMobileMenu } from 'redux/mobileMenuSlice'
import { toggleSearchMenu } from 'redux/searchMenuSlice'

const StyledBurgerWrapper = styled.div`
  display: flex;
`

const StyledSearch = styled.button`
  display: block;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 30px;

  &:focus,
  &:active {
    border: none;
    outline: none;
  }
`

const StyledBurger = styled.button`
  display: block;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:focus,
  &:active {
    border: none;
    outline: none;
  }
`

const StyledImage = styled.img`
  width: 42px;

  ${media.desktopM} {
    width: 30px;
  }
`

export default function MobileBurger() {
  const dispatch = useDispatch()
  const location = useLocation()
  const isMobileMenuOpen = useSelector((state) => state.mobileMenu)
  const isSearchMenuOpen = useSelector((state) => state.searchMenu)
  const isMenusOpen = isMobileMenuOpen || isSearchMenuOpen
  const showSearch =
    location.pathname.includes('regions') ||
    location.pathname.includes('genres') ||
    location.pathname.includes('songs') ||
    location.pathname === '/'

  function openMobileMenu() {
    dispatch(toggleMobileMenu(true))
  }

  function openSearchMenu() {
    dispatch(toggleSearchMenu(true))
  }

  function closeMenus() {
    dispatch(toggleMobileMenu(false))
    dispatch(toggleSearchMenu(false))
  }

  return (
    <StyledBurgerWrapper>
      {!isMenusOpen && showSearch && (
        <StyledSearch onClick={openSearchMenu}>
          <StyledImage src="/img/svg/icon_search.svg" alt="open search menu" />
        </StyledSearch>
      )}
      {!isMenusOpen && (
        <StyledBurger onClick={openMobileMenu}>
          <StyledImage
            src="/img/svg/icon_hamburger.svg"
            alt="open mobile menu"
          />
        </StyledBurger>
      )}
      {isMenusOpen && (
        <StyledBurger onClick={closeMenus}>
          <StyledImage src="/img/svg/icon_close.svg" alt="close mobile menu" />
        </StyledBurger>
      )}
    </StyledBurgerWrapper>
  )
}
