import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import GenresBarItem from '../GenresBarItem/GenresBarItem'
import * as media from 'styles/mixins'
import { getLocalized } from 'utils/getLocalized'

const StyledWrapper = styled.div`
  background-image: url('./img/jpg/genres-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-width: 750px;
  height: calc(100vh - 80px);
  min-height: 680px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.desktopM} {
    height: calc(100vh - 50px);
  }

  ${media.tabletM} {
    min-width: 0;
    min-height: 0;
    height: auto;
  }
`

const StyledList = styled.ul`
  position: relative;
  width: 75%;
  height: 75%;
  min-height: 520px;

  ${media.tabletM} {
    padding: 60px 0px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
    height: auto;
    min-height: calc(100vh - 50px);
  }
`

const StyledItemPlain = styled.li``

export default function GenresCircle(props) {
  const genres = useSelector((state) => state.genres.genresStructured)

  return (
    <StyledWrapper>
      {genres && (
        <StyledList>
          {Object.values(genres).map((genre) => (
            <StyledItemPlain key={genre.id}>
              <GenresBarItem
                key={genre.id}
                name={getLocalized(genre, 'name')}
                image={genre.icon}
                link={genre.id}
                region={props.region}
                rotation="0"
              />
            </StyledItemPlain>
          ))}
        </StyledList>
      )}
    </StyledWrapper>
  )
}
