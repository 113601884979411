import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import * as media from 'styles/mixins'

const StyledListItem = styled.div`
  ${media.tabletM} {
    display: flex;
    align-items: center;
    min-width: 310px;
    margin-bottom: 20px;
  }
`

export default function GenresBarItem(props) {
  const StyledImage = styled.img`
    width: 132px;
    height: auto;
    max-height: 132px;
    transform: rotate(-${props.rotation}deg);

    ${media.tabletM} {
      order: 1;
    }
  `

  const StyledName = styled.button`
    font-family: 'Innerspace', serif;
    text-transform: uppercase;
    word-spacing: 8px;
    font-size: 12px;
    padding: 10px;
    border: none;
    background: transparent;
    cursor: pointer;
    transform: rotate(-${props.rotation}deg);

    &:first-letter {
      font-size: 18px;
    }

    ${media.tabletM} {
      font-size: 24px;
      padding: 14px;
      order: 2;
  `

  return (
    <Link
      to={
        props.region
          ? `/genres/${props.link}?region=${props.region}`
          : `/genres/${props.link}`
      }
    >
      <StyledListItem>
        <StyledName>{props.name}</StyledName>
        <StyledImage
          src={`${process.env.REACT_APP_CMS}${props.image.url}`}
          alt={props.name}
        />
      </StyledListItem>
    </Link>
  )
}
