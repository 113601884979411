import styled from 'styled-components'
import { tabletM, mobile, mobileSM, desktopSM } from 'styles/mixins'

export const StyledInfoWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
`

export const StyledContentWrapper = styled.div`
  background-image: url('/img/jpg/song_BG.jpg');
  filter: saturate(50%);
  background-size: cover;
  position: relative;
  border-bottom: 1px solid #bda384;
  padding: 32px;
`

export const StyledTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;

  ${mobile} {
    display: block;
  }
`
export const StyledNotesWrapper = styled.div`
  position: relative;
  padding: 32px;
  background: url('/img/jpg/song_BG.jpg');
  filter: saturate(50%);
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #bda384;

  @media (max-width: 600px) {
    padding: 10px 14px 14px 32px;
  }
`

export const StyledText = styled.div`
  flex-basis: 270px;
  flex-grow: 1;
  max-width: 45%;

  & p {
    color: #353535;
    font-family: Open Sans, sans-serif;
    font-weight: regular;
    text-transform: none;
    font-size: 14px;
    line-height: 22px;
    white-space: pre-line;
    margin-bottom: 20px;
  }

  ${tabletM} {
    max-width: 100%;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`

export const StyledTextItalic = styled.p`
  color: #353535;
  font-family: Open Sans, sans-serif;
  font-weight: regular;
  font-style: italic;
  text-transform: none;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-line;
  margin-bottom: 20px;
`

export const StyledTextFull = styled.p`
  max-width: 100%;
  color: #353535;
  text-transform: none;
  font-family: Open Sans, sans-serif;
  font-weight: regular;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-line;
`
export const StyledFormWrapper = styled.div`
  background-image: url('/img/jpg/song_BG.jpg');
  filter: saturate(50%);
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 48px;

  @media (max-width: 600px) {
    padding: 14px 48px;
  }
`
export const StyledForm = styled.form`
  display: flex;
`
export const StyledRadio = styled.input`
  width: 1px;
  height: 1px;
  outline: none;

  &:checked {
    & + label {
      color: #fff;
    }

    & + label img {
      opacity: 1;
    }
  }
`
export const StyledLabel = styled.label`
  position: relative;
  z-index: 20;
  color: #353535;
  font-family: Innerspace, sans-serif;
  word-spacing: 8px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 36px;
  }

  &:hover {
    & img {
      opacity: 0.2;
    }
  }
`

export const StyledHover = styled.img`
  position: absolute;
  top: 45%;
  left: 50%;
  width: calc(100% + 20px);
  height: 36px;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;

  ${desktopSM} {
    height: 34px;
  }
`

export const StyledVideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  background: url('/img/jpg/song_BG.jpg');
  filter: saturate(50%);
  border-bottom: 1px solid #bda384;

  @media (max-width: 600px) {
    padding: 10px 14px 14px 32px;
  }
`
export const StyledNotes = styled.img`
  max-width: 100%;
`

export const StyledDownloadButton = styled.a`
  position: absolute;
  top: -4px;
  right: 6px;
  z-index: 60;
  width: 24px;
  height: 28px;
  border: none;
  outline: none;
  display: block;
  cursor: pointer;
  background: transparent;

  ${mobileSM} {
    right: -22px;
  }
`
export const StyledHeading = styled.h3`
  display: block;
  color: #353535;
  font-family: Open Sans;
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 22px;
`

export const StyledLocation = styled.h4`
  color: #353535;
  font-family: Open Sans;
  font-weight: normal;
  font-style: italic;
  text-transform: none;
  font-size: 12px;
  line-height: 22px;
`

export const StyledHeadingWrapper = styled.div`
  padding: 16px 32px;
  background: url('/img/jpg/song_BG.jpg');
  filter: saturate(50%);
  cursor: pointer;
`
