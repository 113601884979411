import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'

import songsAPI from 'api/songsAPI'
import { getLocalized } from 'utils/getLocalized'

import {
  StyledInfoWrapper,
  StyledContentWrapper,
  StyledForm,
  StyledFormWrapper,
  StyledLabel,
  StyledHover,
  StyledNotes,
  StyledRadio,
  StyledText,
  StyledTextItalic,
  StyledTextFull,
  StyledTextWrapper,
  StyledNotesWrapper,
  StyledVideoWrapper,
  StyledDownloadButton,
  StyledHeadingWrapper,
  StyledHeading,
  StyledLocation,
} from './styled'
import { setIsPlayerMinimized } from 'redux/accordionSlice'

export default function SongInfo({ song }) {
  const dispatch = useDispatch()

  const { isPlayerMinimized } = useSelector((state) => state.accordion)
  const language = useSelector((state) => state.language.local)

  const [bookmark, setBookmark] = useState('')
  const [downloadTextLink, setDownloadTextLink] = useState('')

  useEffect(() => {
    if (song?.id) {
      getTextFile()
    }
  })

  const getUrl = (item) => {
    if (item) {
      return `${process.env.REACT_APP_CMS}${item}`
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const handleMediaChange = (event) => {
    setBookmark(
      event.target.htmlFor ? event.target.htmlFor : event.target.value
    )
  }

  const handleHeadingClick = () => {
    setBookmark('text')
    dispatch(setIsPlayerMinimized(false))
  }

  const getTextFile = async () => {
    const text = await songsAPI.fetchSongText(song.id)
    if (text?.data) {
      setDownloadTextLink(getUrl(text.data.url))
    }
  }

  return (
    <StyledInfoWrapper>
      {song && (
        <StyledHeadingWrapper onClick={handleHeadingClick}>
          <StyledLocation>
            {song?.ethnic_regions && song?.ethnic_regions?.length > 0
              ? `${getLocalized(song.ethnic_regions[0], 'name')}, `
              : ''}
            {song?.genres && song?.genres?.length > 0
              ? language === 'ukr'
                ? `${getLocalized(song.genres[0], 'name_singular')}`
                : `${getLocalized(song.genres[0], 'name')}`
              : ''}
          </StyledLocation>
          <StyledHeading>{getLocalized(song, 'name')}</StyledHeading>
          <StyledLocation>{getLocalized(song, 'location')}</StyledLocation>
        </StyledHeadingWrapper>
      )}
      {!isPlayerMinimized && (
        <>
          <StyledFormWrapper>
            <StyledForm onSubmit={handleSubmit}>
              {song?.text_ukr && (
                <>
                  <StyledLabel htmlFor="text" onClick={handleMediaChange}>
                    <StyledRadio
                      type="radio"
                      name="tabs"
                      value="text"
                      id="text"
                      checked={bookmark === 'text'}
                      onChange={handleMediaChange}
                    />
                    <FormattedMessage id="text" />
                    <StyledHover src="/img/png/active_and_hover.png" />
                  </StyledLabel>
                </>
              )}
              {!!song?.notes?.length && (
                <>
                  <StyledLabel htmlFor="notes" onClick={handleMediaChange}>
                    <StyledRadio
                      type="radio"
                      name="tabs"
                      value="notes"
                      id="notes"
                      checked={bookmark === 'notes'}
                      onChange={handleMediaChange}
                    />
                    <FormattedMessage id="notes" />
                    <StyledHover src="/img/png/active_and_hover.png" />
                  </StyledLabel>
                </>
              )}
              {song?.youtude_video && (
                <>
                  <StyledLabel htmlFor="video" onClick={handleMediaChange}>
                    <StyledRadio
                      type="radio"
                      name="tabs"
                      value="video"
                      id="video"
                      checked={bookmark === 'video'}
                      onChange={handleMediaChange}
                    />
                    <FormattedMessage id="video" />
                    <StyledHover src="/img/png/active_and_hover.png" />
                  </StyledLabel>
                </>
              )}
            </StyledForm>
          </StyledFormWrapper>

          {bookmark === 'text' && (
            <StyledContentWrapper>
              <StyledTextWrapper>
                <StyledDownloadButton
                  download={`${song?.name_eng}.txt`}
                  title="Download text"
                  target="_blank"
                  href={downloadTextLink}
                >
                  <img
                    width="24"
                    height="28"
                    src="/img/svg/Control_download.svg"
                    alt="download"
                  />
                </StyledDownloadButton>
                <StyledText>
                  <p>{song?.text_ukr}</p>
                  {song?.recorded_by_ukr && (
                    <StyledTextItalic>
                      Записувачі: {song?.recorded_by_ukr}
                    </StyledTextItalic>
                  )}
                  {song?.sang_by_ukr && (
                    <StyledTextItalic>
                      Виконавці: {song?.sang_by_ukr}
                    </StyledTextItalic>
                  )}
                </StyledText>
                <StyledText>
                  <p>{song?.text_eng}</p>
                  {song?.recorded_by_eng && (
                    <StyledTextItalic>
                      Recorded by: {song?.recorded_by_eng}
                    </StyledTextItalic>
                  )}
                  {song?.sang_by_eng && (
                    <StyledTextItalic>
                      Sang by: {song?.sang_by_eng}
                    </StyledTextItalic>
                  )}
                </StyledText>
              </StyledTextWrapper>
              {song?.translation && (
                <StyledTextWrapper>
                  <StyledTextFull>{song?.translation}</StyledTextFull>
                </StyledTextWrapper>
              )}
            </StyledContentWrapper>
          )}
          {bookmark === 'notes' && (
            <StyledNotesWrapper>
              {song?.notes?.length &&
                song.notes.map((note, i) => (
                  <div key={`music_sheets_${i}`}>
                    <StyledDownloadButton
                      download={`${song.name_eng}_sheet.png`}
                      title="Download music sheet"
                      target="_blank"
                      href={getUrl(note?.url)}
                    >
                      <img
                        width="24"
                        height="28"
                        src="/img/svg/Control_download.svg"
                        alt="download"
                      />
                    </StyledDownloadButton>
                    <StyledNotes src={getUrl(note?.url)} />
                  </div>
                ))}
            </StyledNotesWrapper>
          )}
          {bookmark === 'video' && (
            <StyledVideoWrapper>
              <iframe
                width="560"
                height="315"
                src={song?.youtude_video}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Youtube"
              />
            </StyledVideoWrapper>
          )}
        </>
      )}
    </StyledInfoWrapper>
  )
}
