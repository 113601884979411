import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import songsAPI from 'api/songsAPI'

export const getStates = createAsyncThunk(
  'states/getStates',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().states.states) {
      return thunkAPI.getState().states.states
    }
    const states = await songsAPI.fetchStates()
    return states.data
  }
)

const statesSlice = createSlice({
  name: 'states',
  initialState: { states: null },
  reducers: {},
  extraReducers: {
    [getStates.fulfilled]: (state, { payload }) => {
      state.states = payload
    },
  },
})

export const statesReducer = statesSlice.reducer
