import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  StyledClickDiv,
  StyledWrapper,
  StyledIframe,
  StyledName,
  StyledGarbuzWrapper,
  StyledGarbuz,
  StyledHorseWrapper,
  StyledHorse,
  StyledKorovaiWrapper,
  StyledKorovai,
  StyledKorovaiRibbonOne,
  StyledKorovaiRibbonTwo,
  StyledKorovaiRibbonThree,
  StyledLastivkaWrapper,
  StyledLastivka,
  StyledLastivkaTopWing,
  StyledLastivkaBottomWing,
  StyledNamystoWrapper,
  StyledNamysto,
  StyledNest,
  StyledSnipWrapper,
  StyledSnip,
  StyledVinokWrapper,
  StyledVinok,
  StyledZirkaWrapper,
  StyledZirka,
  StyledPatyk,
  StyledCrossWrapper,
  StyledCross,
  StyledCatWrapper,
  StyledCat,
  StyledCatName,
} from './styled'
import { getLocalized } from 'utils/getLocalized'
import SearchBar from 'components/SearchBar/SearchBar'

export default function GenresRoom() {
  const genres = useSelector((state) => state.genres.genresStructured)

  const [openedList, setOpenedList] = useState('')

  return (
    <>
      {genres && (
        <>
          <StyledWrapper>
            <StyledClickDiv onClick={() => setOpenedList('')} />
            <SearchBar openedList={openedList} setOpenedList={setOpenedList} />
            <StyledIframe src="./animations/Genres/Genres.html" />
            <Link to={'genres/5fab0b3619842f7945fac115'}>
              <StyledGarbuzWrapper>
                <StyledName>
                  {getLocalized(genres['5fab0b3619842f7945fac115'], 'name')}
                </StyledName>
                <StyledGarbuz />
              </StyledGarbuzWrapper>
            </Link>
            <Link to={'genres/5fab0a5f19842f7945fac10f'}>
              <StyledHorseWrapper>
                <StyledName>
                  {getLocalized(genres['5fab0a5f19842f7945fac10f'], 'name')}
                </StyledName>
                <StyledHorse />
              </StyledHorseWrapper>
            </Link>
            <Link to={'genres/5fab0b5019842f7945fac117'}>
              <StyledKorovaiWrapper>
                <StyledName>
                  {getLocalized(genres['5fab0b5019842f7945fac117'], 'name')}
                </StyledName>
                <StyledKorovai />
                <StyledKorovaiRibbonOne />
                <StyledKorovaiRibbonTwo />
                <StyledKorovaiRibbonThree />
              </StyledKorovaiWrapper>
            </Link>
            <Link to={'genres/5fab09bf19842f7945fac109'}>
              <StyledLastivkaWrapper>
                <StyledName>
                  {getLocalized(genres['5fab09bf19842f7945fac109'], 'name')}
                </StyledName>
                <StyledLastivkaTopWing />
                <StyledLastivka />
                <StyledLastivkaBottomWing />
                <StyledNest />
              </StyledLastivkaWrapper>
            </Link>
            <Link to={'genres/5fab0aa719842f7945fac111'}>
              <StyledNamystoWrapper>
                <StyledNamysto />
                <StyledName>
                  {getLocalized(genres['5fab0aa719842f7945fac111'], 'name')}
                </StyledName>
              </StyledNamystoWrapper>
            </Link>
            <Link to={'genres/5fab0a1519842f7945fac10d'}>
              <StyledSnipWrapper>
                <StyledSnip />
                <StyledName>
                  {getLocalized(genres['5fab0a1519842f7945fac10d'], 'name')}
                </StyledName>
              </StyledSnipWrapper>
            </Link>
            <Link to={'genres/5fab09fc19842f7945fac10b'}>
              <StyledVinokWrapper>
                <StyledVinok />
                <StyledName>
                  {getLocalized(genres['5fab09fc19842f7945fac10b'], 'name')}
                </StyledName>
              </StyledVinokWrapper>
            </Link>
            <Link to={'genres/5fab09a119842f7945fac107'}>
              <StyledZirkaWrapper>
                <StyledZirka />
                <StyledPatyk />
                <StyledName>
                  {getLocalized(genres['5fab09a119842f7945fac107'], 'name')}
                </StyledName>
              </StyledZirkaWrapper>
            </Link>
            <Link to={'genres/5fab0b7c19842f7945fac119'}>
              <StyledCrossWrapper>
                <StyledCross />
                <StyledName>
                  {getLocalized(genres['5fab0b7c19842f7945fac119'], 'name')}
                </StyledName>
              </StyledCrossWrapper>
            </Link>
            <Link to={'genres/5fab0acd19842f7945fac113'}>
              <StyledCatWrapper>
                <StyledCatName>
                  {getLocalized(genres['5fab0acd19842f7945fac113'], 'name')}
                </StyledCatName>
                <StyledCat />
              </StyledCatWrapper>
            </Link>
          </StyledWrapper>
        </>
      )}
    </>
  )
}
