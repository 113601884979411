import { createSlice } from '@reduxjs/toolkit'

const mobileMenuSlice = createSlice({
  name: 'mobileMenu',
  initialState: false,
  reducers: {
    toggleMobileMenu: (_, action) => action.payload,
  },
})

export const { toggleMobileMenu } = mobileMenuSlice.actions

export const mobileMenuReducer = mobileMenuSlice.reducer
