import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Modal from 'components/Modal/Modal'
import Iframe from 'components/Iframe/Iframe'
import MobileMenu from 'components/MobileMenu/MobileMenu'
import SearchMenu from 'components/SearchMenu/SearchMenu'
import SearchBar from 'components/SearchBar/SearchBar'
import { desktopM } from 'styles/mixins'
import { useQuery } from 'hooks/useQuery'

const StyledHeaderBackground = styled.div`
  background-image: url('/img/jpg/all_songs_BG.jpg');
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 3;
  width: 100%;
  height: calc(100vh - 80px);

  ${desktopM} {
    height: calc(100vh - 50px);
  }
`

export default function SongsScreen() {
  const query = useQuery()
  const genre = query.getAll('genre')
  const region = query.getAll('region')
  const ethnic_region = query.getAll('ethnic_region')
  const search = query.get('search')
  const notes = query.get('notes')
  const video = query.get('video')

  const [openedList, setOpenedList] = useState('')

  const isMobileMenuOpen = useSelector((state) => state.mobileMenu)
  const isSearchMenuOpen = useSelector((state) => state.searchMenu)

  return (
    <>
      {isMobileMenuOpen && <MobileMenu />}
      {isSearchMenuOpen && <SearchMenu />}
      <StyledHeaderBackground>
        <SearchBar
          selectedGenres={genre}
          selectedEthnic_regions={ethnic_region}
          selectedRegions={region}
          search={search}
          notes={notes}
          video={video}
          openedList={openedList}
          setOpenedList={setOpenedList}
        />
        <Iframe src="/animations/Songs/all_songs_animation.html" />
        <Modal
          genres={genre}
          ethnic_regions={ethnic_region}
          regions={region}
          search={search}
          notes={notes}
          video={video}
          setOpenedList={setOpenedList}
        />
      </StyledHeaderBackground>
    </>
  )
}
