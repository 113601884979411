import React from 'react'
import styled from 'styled-components'

import * as media from 'styles/mixins'
import styles from './door.module.css'

export default function Door(props) {
  const StyledDoorWrapper = styled.div((props) => ({
    position: 'absolute',
    bottom: props.offsetMobileY,
    left: '50%',
    zIndex: 10,
    width: '162px',
    height: '75px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: `scale(1.2) translateX(${props.offsetMobileX})`,
    [media.mobileS]: {
      transform: 'scale(0.8)',
      bottom: '-2%',
      left: '30%',
    },
    [media.tablet]: {
      left: '50%',
      bottom: '10vh',
      transform: `scale(1.2) translate(${props.offsetTabletX}, ${props.offsetTabletY})`,
    },
    [media.desktopS]: {
      transform: `scale(1.2) translate(${props.offsetX}, ${props.offsetY}px)`,
    },
    [media.desktopL]: {
      transform: `scale(1.2) translate(-80px, 0px)`,
    },
    [media.desktopXl]: {
      transform: `scale(1.5) translate(-80px, -5px)`,
    },
  }))

  return (
    <StyledDoorWrapper {...props}>
      <div
        className={styles.windowBack}
        style={{
          background: `url("./img/png/windows_sprite.png") ${props.spritePositionBackX} ${props.spritePositionBackY}`,
          width: props.fullWidth,
          height: props.fullHeight,
        }}
      />
    </StyledDoorWrapper>
  )
}
